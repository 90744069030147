// src/pages/LoginPage.tsx
import React, { useContext, useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/ZDHRK_Logo.png';
import image_login from '../../assets/login.jpg';
import { AuthContext } from '../../context/auth/AuthContext';
import { CustomInput } from '../../components/inputs/CustomInput';
import { CustomModal } from '../../components/modals/CustomModal';
import { LoadingModal } from '../../components/modals/LoadingModal'; // Asegúrate de tener este componente
import { SubmitButton } from '../../components/buttons/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { ModalState, RoleStatus } from '../../interfaces/appInterfaces';
 // Importa la interfaz

export const LoginPage: React.FC = () => {
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();
  const { errorMessage, signIn } = useContext(AuthContext);
  const currentLanguage = i18n.language;

  // Estado para manejar los modales con tipado
  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    description: '',
    visible: false,
    navigation: false,
    activityIndicator: false,
  });

  // Estado para manejar la carga
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<RoleStatus>(RoleStatus.Client); // Estado para pestañas

  useEffect(() => {
    if (errorMessage.length === 0) {
      setModalState({
        title: activeTab === RoleStatus.Client ? t('title.login') : t('title.admin_login'),
        description: '',
        visible: false,
        navigation: false,
        activityIndicator: false,
      });
    } else {
      setModalState({
        title: t('title.error'),
        description: errorMessage,
        visible: true,
        navigation: false,
        activityIndicator: false, // Asegúrate de incluir esta propiedad
      });
      setLoading(false); // Detener el loading en caso de error
    }
  }, [errorMessage, t, activeTab]);

  const handleForgotPassword = () => {
    navigate("/recover-password-page", { replace: true });
  }

  const onCloseModal = () => {
    setModalState({
      title: activeTab === RoleStatus.Client ? t('title.login') : t('title.admin_login'),
      description: '',
      visible: false,
      navigation: false,
      activityIndicator: false,
    });
  }

  const changeLanguage = (languageSelected: string) => {
    i18n.changeLanguage(languageSelected)
  }

  return (
    <div className='login-main-container'>
      <section className='section-left'>
        <p>{t('description.login')}</p>
        <img className="image-gest-activity" src={image_login} alt="Imagen gest activity" />
      </section>
      <section className='section-right'>  
        <div>
          <p className="login-form-title">
            {activeTab === RoleStatus.Client ? t('title.login') : t('title.admin_login')}
          </p>
          {/* Selección de Idioma */}
          <div className={"container-flags"}>
            <label
              className={`language ${currentLanguage === 'ca' ? 'active' : ''}`}
              onClick={() => changeLanguage('ca')}
            >
              {t('label.catalan')}
            </label>
            <label
              className={`language ${currentLanguage === 'es' ? 'active' : ''}`}
              onClick={() => changeLanguage('es')}
            >
              {t('label.spanish')}
            </label>
          </div>
        </div>

        <div className="tabs">
          <button
            className={`tab-button ${activeTab === RoleStatus.Client ? 'active' : ''}`}
            onClick={() => setActiveTab(RoleStatus.Client)}
          >
            {t('label.client_login')}
          </button>
          <button
            className={`tab-button ${activeTab === RoleStatus.Admin ? 'active' : ''}`}
            onClick={() => setActiveTab(RoleStatus.Admin)}
          >
            {t('label.admin_login')}
          </button>
        </div>

        {/* Formulario de Usuario */}
        {activeTab === RoleStatus.Client && (
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={async ({ username, password }) => {
              setLoading(true); // Iniciar loading
              setModalState({
                title: activeTab === RoleStatus.Client ? t('title.login') : t('title.admin_login'),
                description: t('description.checking_credentials'),
                visible: true,
                navigation: false,
                activityIndicator: true,
              });
              try {
                await signIn({ username, password, role: RoleStatus.Client });
                // Puedes redirigir o realizar otras acciones aquí después del inicio de sesión exitoso
              } catch (error) {
                // Manejo de errores si es necesario
                setLoading(false);
              }
            }}
            validationSchema={
              Yup.object({
                username: Yup.string()
                  .required(t('validation_error.required')),
                password: Yup.string()
                  .required(t('validation_error.required')),
              })
            }
          >
            {({ handleSubmit, isValid, dirty }) => (
              <div className="login-form">
                <Field
                  component={CustomInput}
                  label={t('label.username')}
                  name="username"
                  placeholder={t('placeholder.username')}
                  autoCapitalize="none"
                />
                <Field
                  component={CustomInput}
                  label={t('label.password')}
                  name="password"
                  type="password"
                  placeholder={t('placeholder.password')}
                />
                {/* <label
                  className="textPrimary"
                  onClick={handleForgotPassword}
                >
                  {t('label.lost_password')}
                </label> */}
                <SubmitButton
                  description={t('button.login')}
                  isValid={isValid}
                  dirty={dirty}
                  handleSubmit={handleSubmit}
                />
              </div>
            )}
          </Formik>
        )}

        {/* Formulario de Administrador */}
        {activeTab === RoleStatus.Admin && (
          <Formik
            initialValues={{
              adminUsername: '',
              adminPassword: '',
            }}
            onSubmit={async ({ adminUsername, adminPassword }) => {
              setLoading(true); // Iniciar loading
              setModalState({
                title: t('title.admin_login'),
                description: t('description.checking_credentials'),
                visible: true,
                navigation: false,
                activityIndicator: true,
              });
              try {
                await signIn({ username: adminUsername, password: adminPassword, role: RoleStatus.Admin });
                // Puedes redirigir o realizar otras acciones aquí después del inicio de sesión exitoso
              } catch (error) {
                // Manejo de errores si es necesario
                setLoading(false);
              }
            }}
            validationSchema={
              Yup.object({
                adminUsername: Yup.string()
                  .required(t('validation_error.required')),
                adminPassword: Yup.string()
                  .required(t('validation_error.required')),
              })
            }
          >
            {({ handleSubmit, isValid, dirty }) => (
              <div className="login-form">
                <Field
                  component={CustomInput}
                  label={t('label.admin_email')}
                  name="adminUsername"
                  placeholder={t('placeholder.email')}
                  autoCapitalize="none"
                />
                <Field
                  component={CustomInput}
                  label={t('label.admin_password')}
                  name="adminPassword"
                  type="password"
                  placeholder={t('placeholder.password')}
                />
                {/* <label
                  className="textPrimary"
                  onClick={handleForgotPassword}
                >
                  {t('label.lost_password')}
                </label> */}
                <SubmitButton
                  description={t('button.login')}
                  isValid={isValid}
                  dirty={dirty}
                  handleSubmit={handleSubmit}
                />
              </div>
            )}
          </Formik>
        )}
      </section>

      {/* Mostrar LoadingModal durante la carga */}
      {loading && <LoadingModal message={t('description.checking_credentials')} />}

      {/* Mostrar CustomModal para errores */}
      {modalState.visible && !loading && (
        <CustomModal modalState={modalState} onCloseModal={onCloseModal} />
      )}
    </div>
  );
};