//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { DataGPS, ServiceInfo, ServiceZDB } from "../../interfaces/appInterfaces";


export interface ServiceZDBState {
    errorMessage: string;
    serviceZDB: ServiceZDB | null;
    driverInfo: DataGPS | null;
    serviceInfo: ServiceInfo | null;
}

type ServiceZDBAction = 
    | { type: 'selected', payload: { serviceZDB: ServiceZDB } }
    | { type: 'setDriverLocation', payload: { driverInfo: DataGPS} }
    | { type: 'setServiceInfo', payload: { serviceInfo: ServiceInfo} }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'clearState' }

 export const serviceZDBReducer = ( state: ServiceZDBState, action: ServiceZDBAction ): ServiceZDBState => {

        switch (action.type) {
            case 'setDriverLocation':
                return {
                    ...state,
                    driverInfo: action.payload.driverInfo,
                }
            case 'selected':
                return {
                    ...state,
                    serviceZDB: action.payload.serviceZDB,
                }
            case 'setServiceInfo':
                return {
                    ...state,
                    serviceInfo: action.payload.serviceInfo,
                }

                case 'addError':
                    return {
                        ...state,
                        errorMessage: action.payload
                    }
                case 'removeError':
                    return {
                        ...state,
                        errorMessage: '',
                    }
                case 'clearState':
                    return {
                        errorMessage: '',
                        driverInfo: null,
                        serviceZDB: null,
                        serviceInfo: null,
                    }
            default:
               return state;
        }
}