 //***************************************
 //
 // Creado por: Miguel Sánchez
 // 
 //****************************************

 import { sha1 } from 'js-sha1';

 export const calculateHashSHA1 = ( inputString: string ): string => {
   // Calcular el hash SHA-1
   const hash = sha1(inputString);
 
   return hash;
 };
