import { useTranslation } from 'react-i18next';

interface Props {
  search: string;
  onHandleSearch: ()=> void;
  handleChange: ( e: string )=> void;
}

export const SearchInput = ( { onHandleSearch, handleChange, search }: Props ) => {

  const { t } = useTranslation('global');

  return (
    <div className="search-input-component">

        <label className="label"> { t('label.search') } </label>
        <div className="search-input">
          <input 
            className="search-input-field"
            type="search"
            placeholder={ t('placeholder.search') }
            value={ search }
            onChange={ (e) => handleChange( e.target.value ) }
          />
          <button 
            className="search-input-button"
            onClick={ onHandleSearch }
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
    </div>
  )
}
