//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import L from 'leaflet';

export const BusIcon = L.icon({
  iconUrl: require('../../assets/bus-icon-12983.png'),
  iconSize: [32,32], 
});
