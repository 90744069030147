import { createContext, useEffect, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { LoginData, LoginResponse, RegisterData, RegisterResponse, RequestResetPasswordResponse, ResetPasswordData, ResetPasswordResponse, ResponseStatus, RoleStatus, SendVerificationCodeData, SendVerificationCodeResponse, User, ErrorInfo, TokenResponse } from '../../interfaces/appInterfaces';
import { authReducer, AuthState } from './authReducer';
import { getEmpresa } from '../../helpers/getEmpresa';
import Keys from '../../constants/Keys';
import { calculateHashSHA1 } from '../../helpers/calculateHashSHA1';

type AuthContextProps = {
    errorMessage: string;
    infoMessage: string;
    token: string | null;
    user: User | null;
    status: 'checking' | 'authenticated' | 'not-authenticated';
    signUp: ( registerData: RegisterData) => Promise<RegisterResponse>;
    signIn: ( loginData: LoginData ) => Promise<LoginResponse>;
    logOut: () => void;
    // requestResetPassword: ( email: string ) => Promise<RequestResetPasswordResponse>;
    // sendVerificationCode: ( sendVerificationCodeData: SendVerificationCodeData ) => Promise<SendVerificationCodeResponse>;
    // resetPassword: ( resetPasswordData: ResetPasswordData ) => Promise<ResetPasswordResponse>;
    removeError: () => void;
}

const authInicialState: AuthState = {
    status: 'checking',
    token: null,
    user: null,
    errorMessage: '',
    infoMessage: '',
}

export const AuthContext = createContext( {} as AuthContextProps );

export const AuthProvider = ({ children }: any)=> {

    const [ state, dispatch ] = useReducer( authReducer, authInicialState );

    useEffect(() => {
        checkToken();
    }, []);

    // const checkToken = async() => { 

    //     const token = await localStorage.getItem( 'token');

    //     // No token, no autenticado
    //     if ( !token ) return dispatch({ type:'notAuthenticated' });

    //     // Hay token
    //     try {
    //         const resp = await zdReservasBusApi.get('/auth');
    //         await localStorage.setItem( 'token', resp.data.token );
    
    //         dispatch({
    //                 type: 'signIn',
    //                 payload: {
    //                     token: resp.data.token,
    //                     user: resp.data.user,
    //                 }
    //             });
    //     } catch (error: any) {
    //         if ( error.response.status !== 200 ) {
    //             localStorage.clear();
    //             return dispatch({ type: 'notAuthenticated'})
    //         }
    //     }
    // }

        // COMPROBAR TOKEN 
        const checkToken = async() => { 

            const token = await localStorage.getItem( 'token');
            const name = await localStorage.getItem( 'name');
            const role = await localStorage.getItem( 'role');
    
            // No token, no autenticado
            if ( !token || !role || !name ) return dispatch({ type:'notAuthenticated' });
    
            // Hay token
            try {
                const resp = await getEmpresa( Keys.CODE_COMPANY );
                const role_web = role === 'Client' ? RoleStatus.Client : RoleStatus.Admin;
    
                const dataAdmin  = await resp.adminApi.post( '/tokenValidate', { JSONString: JSON.stringify(
                    { 
                        dbName: resp.DBEmpresa, 
                        token,
                    })});
                
                const data: TokenResponse = JSON.parse( dataAdmin.data.d );
      
                
                if( data.status !== ResponseStatus.Ok ){
                    dispatch({ 
                        type: 'addError',
                        payload: data.errorInfo.errMessage || 'Información incorrecta'
                    })
                } else if ( data.errorInfo.errCode ) {
                    dispatch({ 
                        type: 'addError',
                        payload: data.errorInfo.errMessage || 'Información incorrecta'
                    })
                } else {
        
                    await localStorage.setItem( 'token', token );
                    
                    dispatch({
                        type: 'signIn',
                        payload: {
                            token: token,
                            user: { 
                                name:  name,
                                rol_web: role_web
                            },
                        }
                    });
                }
    
            }  catch (error: any) {
                if ( error.response.status !== 200 ) {
                    localStorage.clear();
                    return dispatch({ type: 'notAuthenticated'})
                }
            }
        }
    
    const signIn = async( { username, password, role }: LoginData ): Promise<LoginResponse> => {
        
        let data = {} as LoginResponse;
        try {

            const resp = await getEmpresa( Keys.CODE_COMPANY );
            const passwordHash: string = calculateHashSHA1(password);

            const typeUser = role === RoleStatus.Client ? 'control_pasajeros' : 'control_pasajeros_tecnicos'

            const dataAdmin  = await resp.adminApi.post( '/Login', { JSONString: JSON.stringify(
                { 
                    dBempresa: resp.DBEmpresa,
                    usuario: username, 
                    password: passwordHash,
                    access: typeUser
                })});

            data = JSON.parse( dataAdmin.data.d );

            if( data.errores !== "" ){
                dispatch({ 
                    type: 'addError',
                    payload: 'Usuario o contraseña incorrectos'
                })
            } else {
    
                await localStorage.setItem( 'token', data.token );
                await localStorage.setItem( 'name', data.name );
                await localStorage.setItem( 'role', role );

                dispatch({
                    type: 'signIn',
                    payload: {
                        token: data.token,
                        user: { 
                            name:  data.name,
                            rol_web: role
                        },
                    }
                });
            }
        
        } catch (error: any) {
            dispatch({ 
                type: 'addError',
                payload: error.response.data.msg || 'Información incorrecta'
            })
        }

        return data;

    };

    const signUp = async( { codeCompany, fullname, email, password }: RegisterData ): Promise<RegisterResponse> => {

        let data: RegisterResponse = { msg: '' };
        try {

            const { data: dataResponse } =  await zdReservasBusApi.post<RegisterResponse>( '/users', { codeCompany, fullname, email, password } )
            data = dataResponse;

    
        } catch (error: any) {

            dispatch({ 
                type: 'addError',
                payload: error.response.data.errors[0].msg || 'Revise la información'
            })
        }

        return data;

    };

    const logOut = async() => {
        await localStorage.removeItem( 'token' );
        dispatch({ type: 'logout' });
    };

    // const requestResetPassword = async( email: string ): Promise<RequestResetPasswordResponse> => {

    //     let data: RequestResetPasswordResponse = { msg: '', email: email };

    //     try {

    //         const { data: dataResponse } = await zdReservasBusApi.post<RequestResetPasswordResponse>( '/auth/requestResetPassword', { email } )
    //         data.msg = dataResponse.msg

    //     } catch (error: any) {
    //         dispatch({ 
    //             type: 'addError',
    //             payload: error.response.data.msg || 'Información incorrecta'
    //         })
    //     }

    //     return data;

    // };

    // const sendVerificationCode = async( { verificationCode, email }: SendVerificationCodeData ): Promise<SendVerificationCodeResponse> => {

    //     let data: SendVerificationCodeResponse = { msg: '', email, verificationCode };
    //     try {

    //         const { data: dataResponse } = await zdReservasBusApi.post<SendVerificationCodeResponse>( '/auth/verifyResetCode', { verificationCode, email } )
    //         data.msg = dataResponse.msg

    //     } catch (error: any) {
    //         dispatch({ 
    //             type: 'addError',
    //             payload: error.response.data.msg || 'Información incorrecta'
    //         })
    //     }

    //     return data;

    // };


    // const resetPassword = async( { email, verificationCode, password }: ResetPasswordData ): Promise<ResetPasswordResponse> => {

    //     let data: ResetPasswordResponse = { msg: '' };

    //     try {

    //         const { data: dataResponse } = await zdReservasBusApi.post<ResetPasswordResponse>( '/auth/resetPassword', { email, verificationCode, password } )
    //         data.msg = dataResponse.msg

    //     } catch (error: any) {
    //         dispatch({ 
    //             type: 'addError',
    //             payload: error.response.data.msg || 'Información incorrecta'
    //         })
    //     }

    //     return data;

    // };

    const removeError = () => {
        dispatch({ 
            type: 'removeError'
        })
    };

    return (
        <AuthContext.Provider value={{
            ...state,
            signIn, 
            signUp,
            logOut,
            //requestResetPassword,
            //sendVerificationCode,
            //resetPassword,
            removeError,
        
        }}>
            { children }
        </AuthContext.Provider>
    )
}