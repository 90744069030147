import { useTranslation } from 'react-i18next';

import { PassengerSectionList } from '../../components/section/PassengerSectionList';


export const PassengersPage = () => {
  
  const { t } = useTranslation('global');

  return (

      <div className="page-container">

        <section className="page-section1-container">
          <div className="page-section1-title-group">
            <h1 className="page-title"> { t('title.passengers') } </h1>
          </div>
        </section>

        <PassengerSectionList />
       
      </div>
  )
}
