//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
import { useReducer } from "react";
import { PlacesContext } from "./PlacesContext"
import { placesReducer } from "./placesReducer";

export interface PlacesState {
    isLoading: boolean,
    driverLocation?: [ number, number ]
} 

const INITIAL_STATE: PlacesState = {
    isLoading: true,
    driverLocation: undefined,
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const PlacesProvider = ({ children }: Props ) => {

    const [ state, dispatch ] = useReducer( placesReducer, INITIAL_STATE );

    const getDriverLocation = async() => {
        dispatch({
            type: 'setDriverLocation',
            payload: {
                isLoading: false,
                driverLocation: [ 41.56308427008756, 2.1603525267304806 ],
            }
        });
    }

    
    return(
        <PlacesContext.Provider value={{
            ...state,
            getDriverLocation,
        }}>
            { children }
        </PlacesContext.Provider>
    )
  
}
