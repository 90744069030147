//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { createContext, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { CreateNoticeData, CreateNoticeResponse, Notice, NoticeListResponse, DeleteNoticeResponse } from '../../interfaces/appInterfaces';
import { noticeReducer, NoticeState } from './noticeReducer';

type NoticeContextProps = {
    notice: Notice | null;
    noticeCreate: ( addNoticeData: CreateNoticeData ) => Promise<CreateNoticeResponse>;
    noticeList: (  pageState: number, search?: string, orderList?: string ) => Promise<NoticeListResponse>;
    noticeDelete: ( itemId: string ) => Promise<DeleteNoticeResponse>;
}

const noticeInicialState: NoticeState = {
    notice: null,
}

export const NoticeContext = createContext({} as NoticeContextProps);

export const NoticeProvider = ({ children }: any ) => {

    const [ state, dispatch ] = useReducer( noticeReducer, noticeInicialState );

    const noticeCreate = async( { title, description, date, codeClient, codeRoute }: CreateNoticeData ): Promise<CreateNoticeResponse> => {
        
        let data: CreateNoticeResponse = { msg: '', notices: [] };
        const dateHour = date.getTime();

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<CreateNoticeResponse>('/notices/create-notice', {
                title, description, dateHour, codeClient, codeRoute
            });
          
            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const noticeList = async( pageState: number, search: string = "", orderList: string = "" ): Promise<NoticeListResponse>=> {
        
        let data = {} as NoticeListResponse;
        data.notices = [];
        const from = pageState * 5 - 5;

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<NoticeListResponse>(`/notices/notice-list?from=${from}&search=${search}&orderlist=${orderList}`);
            data = dataResponse;
        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const noticeDelete = async( itemId: string ): Promise<DeleteNoticeResponse> => {

        let data: DeleteNoticeResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.delete<DeleteNoticeResponse>(`/notices/${itemId}`);

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

         return data;
    }

    return(
        <NoticeContext.Provider value={{
            ...state,
            noticeCreate,
            noticeList,
            noticeDelete
        }}>
            { children }
        </NoticeContext.Provider>
    )
}
