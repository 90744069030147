//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { createContext, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { ClientListZDData, ClientListZDResponse, ClientZD } from '../../interfaces/appInterfaces';
import { clientReducer, ClientState } from './clientReducer';

type ClientContextProps = {
    clientZD: ClientZD | null;
    clientsZD: Array<ClientZD> | null;
    clientsListZDB: ( clientListZDData: ClientListZDData ) => Promise<ClientListZDResponse>;
    clientSelected: ( client: ClientZD ) => void;
    clientNotSelected: () => void;
    emptyZDClients:() => void;
    sortByProperty:  ( property: string ) => void;
}

const clientInicialState: ClientState = {
    // client: null,
    clientZD: null,
    clientsZD: [] as ClientZD[],
}

export const ClientContext = createContext({} as ClientContextProps);

export const ClientProvider = ({ children }: any ) => {

    const [ state, dispatch ] = useReducer( clientReducer, clientInicialState );

    const emptyZDClients = async() => {
        dispatch({
            type: 'empty-list-client-zd',
        });
    }

    const clientsListZDB = async( { empresa, v2, codigo, nombre }: ClientListZDData ): Promise<ClientListZDResponse>=> {
        

        let data = { msg: '', clients: [] as ClientZD[] } as ClientListZDResponse;

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<ClientListZDResponse>(`/clients/clientlistzdb`,{
                empresa, v2, codigo, nombre
            });

        
            data = dataResponse;

            const sortedClients = [...data.clients!].sort((a: ClientZD, b: ClientZD) => (a.Nombre < b.Nombre) ? -1 : 1);

            if ( data.clients ) {
                dispatch({
                    type: 'list-client-zd',
                    payload: {
                        clientsZD: sortedClients,
                    }
                });
            }


        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const clientSelected = async( client: ClientZD )=> {

        dispatch({
            type: 'selected',
            payload: {
                client: client,
            }
        });
    }


    const clientNotSelected = async(  )=> {
        dispatch({
            type: 'not-selected'
        });
    }

    const sortByProperty = async( property: string ) => {
        
        let sortedClients= [] as ClientZD[];

        switch (property) {
            case 'codigo':
                sortedClients = [...state.clientsZD!].sort((a: ClientZD, b: ClientZD) => (Number(a.codigo) < Number(b.codigo)) ? -1 : 1);
                break;
        
            default:
                sortedClients = [...state.clientsZD!].sort((a: ClientZD, b: ClientZD) => (a.Nombre < b.Nombre) ? -1 : 1);
                break;
        }

        dispatch({
            type: 'list-client-zd',
            payload: {
                clientsZD: sortedClients,
            }
        });
            
    
    }
    

    return(
        <ClientContext.Provider value={{
            ...state,
            clientsListZDB,
            clientSelected,
            clientNotSelected,
            emptyZDClients,
            sortByProperty
        }}>
            { children }
        </ClientContext.Provider>
    )
}
