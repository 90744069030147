import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import { PassengerContext } from '../../../context/passenger/PassengerContext';
import { FormInput } from '../../inputs/FormInput';
import { SaveButton } from '../../buttons/SaveButton';
import { CustomModal } from '../../modals/CustomModal';
import { AuthContext } from '../../../context/auth/AuthContext';
import { ResponseStatus } from '../../../interfaces/appInterfaces';

export const PassengerDetails = () => {

    const { t } = useTranslation('global');
    const { token } = useContext(AuthContext);
    const { passenger, passengerEdit } = useContext(PassengerContext);

    const [ modalErrorState, setModalErrorState ] = useState({
        title: '',
        description: '',
        visible: false,
        navigation: false,
        activityIndicator: false,
      });
    
    const onCloseModalError = () => {
        setModalErrorState({
          title: '',
          description: '',
          visible: false,
          navigation: false,
          activityIndicator: false,
        });
    }

    return (
        <>
            <Formik 
                enableReinitialize               
                initialValues={{
                    name: passenger!.name,
                    email: passenger!.email,
                    phone: passenger!.phone,
                    dni: passenger!.dni,
                    disabled: passenger!.disabled,
                }}
                onSubmit={ async( { name, email, phone, dni, disabled }, actions ) => {  

                    const resp = await passengerEdit({ token: token!,id: passenger!.id, name, email, phone, dni, disabled });

                    if ( resp.status === ResponseStatus.Ok ) { 
                        actions.resetForm();
                    }
                }}
                validationSchema={ 

                    Yup.object({
                        name: Yup.string()                               
                                        .required( t('validation_error.required') ),
                        email: Yup.string()
                                    .email(t('validation_error.email_not_valid')),
                        phone: Yup.string()                               
                            .required( t('validation_error.required') ),
                        dni: Yup.string()                               
                            .required( t('validation_error.required') ),
                        disabled: Yup.boolean()
                                .required(t('validation_error.required')),          
                        
                                
                    })
                }
            >
            { ({ handleSubmit, isValid, dirty }) => (
                < div className="detail-client-form" >

                    <h2 className="section-title">{ t('title.general_data') }</h2>

                    <div className="section-inputs">
                        <Field
                            component={ FormInput }
                            label={ t('label.email') } 
                            name="email"
                            type="text"
                            placeholder={ t('placeholder.email') }
                            disabled
                        />

                        <Field
                            component={ FormInput }
                            label={ t('label.fullname') }
                            name="name"
                            type="text"
                            placeholder={ t('placeholder.fullname') }                      
                        />
                    </div>

                    <div className="section-inputs">
                        <Field
                            component={ FormInput }
                            label={ t('label.phone') } 
                            name="phone"
                            type="text"
                            placeholder={ t('placeholder.phone') }
                        />

                        <Field
                            component={ FormInput }
                            label={ t('label.nif') }
                            name="dni"
                            type="text"
                            placeholder={ t('placeholder.nif') }                      
                        />
                    </div>
                                        
                    <h2 className="section-title">{ t('title.attributes') }</h2>

                    <div className="section-inputs">
                        <label className="section-checkbox">
                            <Field type="checkbox" name="disabled" />
                            { t('label.disabled') }
                        </label>
                    </div>
                    
                    <div className="section-buttons">
                        <SaveButton description={ t('button.save') } isValid={ isValid } dirty={ dirty } handleSubmit={ handleSubmit } ClassName="save-button"/>
                    </div>
                    
                </div>
                )}
            </Formik>
            { modalErrorState.visible && <CustomModal modalState={ modalErrorState } onCloseModal={ onCloseModalError }/>}
        </>        
    )
  
}
