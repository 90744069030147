import { NavLink } from 'react-router-dom';
import { SideBarMenuItem } from '../../types/types';
import { classNames } from '../../util/classes';

interface SideBarMenuItemViewProps {
  item: SideBarMenuItem;
  isOpen: boolean;
}

export const SideBarMenuItemView = ( { item, isOpen }: SideBarMenuItemViewProps ) => {

  return (

    <div className="SideBarMenuItemView">
     
      <NavLink to={ item.url } className={ classNames( "navlink", !isOpen && "collapsed" ) } >
          
          <div className={ classNames( "icon" )} >
            <item.icon size="32" className="collapsed"/>
          </div>

          { isOpen && <span className="label"> { item.label } </span> }

      </NavLink>
      {
        !isOpen && <div className="tooltip"> { item.label } </div>   
      }
    </div>
  )
}
