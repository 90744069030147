//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { RouteContext } from '../../../context/route/RouteContext';


export const RouteDetails = () => {

    const { t } = useTranslation('global');
    const { routeZD } = useContext(RouteContext);

    return (

        <div className="detail-info" >
            <div>
                <h4 className="label" >{ t('label.code') }:</h4>
                <p className="info">{ routeZD?.id }</p>
            </div>
            <div>
                <h4 className="label" >{ t('label.route') }:</h4>
                <p className="info">{ routeZD?.routeName }</p>
            </div>
        </div>
  )
}
