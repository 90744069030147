//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { createContext, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { AcceptPassengerResponse, CreatePassengerData, CreatePassengerResponse, DeletePassengerData, DeletePassengerResponse, EditPassengerData, EditPassengerResponse, IPassengersInServiceRequest, IPassengersInServiceResponse, Passenger, PassengerListData, PassengerListResponse, PassengerRegisteredListData, PassengerRegisteredListResponse, ResponseStatus } from '../../interfaces/appInterfaces';
import { passengerReducer, PassengerState } from './passengerReducer';
import { getEmpresa } from '../../helpers/getEmpresa';
import Keys from '../../constants/Keys';

type PassengerContextProps = {
    errorMessage: string;
    passenger: Passenger | null;
    passengerCreate: ( createPassengerData: CreatePassengerData ) => Promise<CreatePassengerResponse>;
    passengerList: ( passengerList: PassengerListData  ) => Promise<PassengerListResponse>;
    passengerDelete: ( deletePassengerData: DeletePassengerData ) => Promise<DeletePassengerResponse>;
    passengerAccept: ( itemId: string ) => Promise<AcceptPassengerResponse>;
    passengerDeny: ( itemId: string ) => Promise<AcceptPassengerResponse>;
    passengerSelected: ( passenger: Passenger ) => void;
    passengerEdit: (  editPassengerData: EditPassengerData )=> Promise<EditPassengerResponse>;
    passengerRegisteredList: ( passengerRegisteredListData: PassengerRegisteredListData  ) => Promise<PassengerRegisteredListResponse>;
    passengersInService: ( passengersInServiceRequest: IPassengersInServiceRequest  ) => Promise<IPassengersInServiceResponse>;
}

const passengerInicialState: PassengerState = {
    errorMessage: '',
    passenger: null,
}

export const PassengerContext = createContext({} as PassengerContextProps);

export const PassengerProvider = ({ children }: any ) => {

    const [ state, dispatch ] = useReducer( passengerReducer, passengerInicialState );

    const passengerCreate = async( { email, nif, phone, password, fullname, actived, codigo }: CreatePassengerData ): Promise<CreatePassengerResponse> => {
        
        let data: CreatePassengerResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<CreatePassengerResponse>('/passengers/create-passenger', {
                email,
                nif,
                phone,
                password,
                fullname,
                state: actived,
                codigo
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const passengerList = async( { token, clientId }: PassengerListData ): Promise<PassengerListResponse>=> {
   
        let data = {} as PassengerListResponse;

             try {
                const resp = await getEmpresa( Keys.CODE_COMPANY );
    
                const dataAdmin  = await resp.adminApi.post( '/passengersList', { JSONString: JSON.stringify(
                    { 
                        token,
                        clientId
                    })});
                
                data = JSON.parse( dataAdmin.data.d );
      
                if( data.status !== ResponseStatus.Ok ){
                    dispatch({ 
                        type: 'addError',
                        payload: data.errorInfo.errMessage || 'Información incorrecta'
                    })
                } else if ( data.errorInfo.errCode ) {
                    dispatch({ 
                        type: 'addError',
                        payload: data.errorInfo.errMessage || 'Información incorrecta'
                    })
                } 
    
            }  catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const passengerDelete = async( { token, id }: DeletePassengerData  ): Promise<DeletePassengerResponse> => {
        
        let data = {} as DeletePassengerResponse;

        try {
            const resp = await getEmpresa( Keys.CODE_COMPANY );
    
            const dataAdmin  = await resp.adminApi.post( '/PassengerDelete', { JSONString: JSON.stringify(
                { 
                    token, id
                })});
            
            data = JSON.parse( dataAdmin.data.d );
            console.log('data:', data);
    
            if( data.status !== ResponseStatus.Ok ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errorInfo.errMessage || 'Información incorrecta'
                })
            } else if ( data.errorInfo.errCode ) {
                dispatch({ 
                    type: 'addError',
                    payload: data.errorInfo.errMessage || 'Información incorrecta'
                })
            } 

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

         return data;
    }

    const passengerAccept = async( itemId: string ): Promise<AcceptPassengerResponse> => {
        
        let data = { } as AcceptPassengerResponse;

        try {
            const { data: dataResponse } = await zdReservasBusApi.put<AcceptPassengerResponse>(`/passengers/passenger-validate/${itemId}`, {
                validated: true,
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

         return data;
    }

    const passengerDeny = async( itemId: string ): Promise<AcceptPassengerResponse> => {
        
        let data = { } as AcceptPassengerResponse;

        try {
            const { data: dataResponse } = await zdReservasBusApi.put<AcceptPassengerResponse>(`/passengers/passenger-validate/${itemId}`, {
                validated: false,
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

         return data;
    }

    const passengerSelected = async( passenger: Passenger )=> {
        
        dispatch({
            type: 'selected',
            payload: {
                passenger: passenger,
            }
        });
    }

    const passengerEdit = async( { token, id, name, email, phone, dni, disabled }: EditPassengerData )=> {
        
        let data = {} as EditPassengerResponse;

        try {

        const resp = await getEmpresa( Keys.CODE_COMPANY );
    
        const dataAdmin  = await resp.adminApi.post( '/PassengerEdit', { JSONString: JSON.stringify(
            { 
                token, id, name, phone, dni, disabled
            })});
        
        data = JSON.parse( dataAdmin.data.d );

        if( data.status !== ResponseStatus.Ok ){
            dispatch({ 
                type: 'addError',
                payload: data.errorInfo.errMessage || 'Información incorrecta'
            })
        } else if ( data.errorInfo.errCode ) {
            dispatch({ 
                type: 'addError',
                payload: data.errorInfo.errMessage || 'Información incorrecta'
            })
        } else {
            dispatch({
                type: 'selected',
                payload: {
                    passenger: {
                        id,
                        dni,
                        name,
                        email,
                        phone,
                        disabled
                    },
                }
            });
        }

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const passengerRegisteredList = async( { months }: PassengerRegisteredListData ): Promise<PassengerRegisteredListResponse>=> {
   
        let data = null;
   
        try {
            const { data: dataResponse } = await zdReservasBusApi.get<PassengerRegisteredListResponse>(`/passengers/passenger-registered-list?months=${months}`);
            data = dataResponse;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const passengersInService = async( { token, id }: IPassengersInServiceRequest  ): Promise<IPassengersInServiceResponse> => {
        
        let data = {} as IPassengersInServiceResponse;

        console.log('token:',token);
        console.log('id:',id);

        try {
            const resp = await getEmpresa( Keys.CODE_COMPANY );
    
            const dataAdmin  = await resp.adminApi.post( '/PassengersInService', { JSONString: JSON.stringify(
                { 
                    token, id
                })});

           
                console.log('dataAdmin',dataAdmin.data.d)
            data = JSON.parse( dataAdmin.data.d );
    
          

            if( data.status !== ResponseStatus.Ok ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errorInfo.errMessage || 'Información incorrecta'
                })
            } else if ( data.errorInfo.errCode ) {
                dispatch({ 
                    type: 'addError',
                    payload: data.errorInfo.errMessage || 'Información incorrecta'
                })
            } 

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

         return data;
    }

    return(
        <PassengerContext.Provider value={{
            ...state,
            passengerCreate,
            passengerList,
            passengerDelete,
            passengerAccept,
            passengerDeny,
            passengerSelected,
            passengerEdit,
            passengerRegisteredList,
            passengersInService
        }}>
            { children }
        </PassengerContext.Provider>
    )
}
