//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext, useEffect, useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ServiceZDBContext } from '../../context/serviceZDB/ServiceZDBContext';
import { LoadingPage } from '../loading/LoadingPage';
import { TabButton } from '../../components/buttons/TabButton';
import { WaypointSectionList } from '../../components/section/WaypointSectionList';
import { ServiceZDBDetails } from '../../components/tabs/detail-servicesZDB/ServiceZDBDetails';
import { LocationServiceSection } from '../../components/section/LocationServiceSection';
import { BookingsServiceSection } from '../../components/section/BookingsServiceSection';
import { checkOutDate } from '../../util/utils';
import { UseServiceSection } from '../../components/section/UseServiceSection';


export const ServiceZDBDetailPage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const { serviceZDB } = useContext( ServiceZDBContext );

  const [ toggleState, setToggleState ] = useState('details');
  const [ activeService, setActiveService ] = useState(false);

  const toggleTab = (tab: string) => {
    setToggleState(tab);
  };

  useEffect(() => {
    if(serviceZDB){
      if ( checkOutDate( serviceZDB!.serviceDate, serviceZDB!.stops[  serviceZDB!.stops.length -1 ].waypointTime ) ) {
        setActiveService(true);
      }
    }
  }, []);


  return (
    !serviceZDB
    ?   <Navigate to="/routes" />
    : 
      <div className="page-container" >
        <section className="page-section1-container" >
          <div className="page-section1-back-group" >
            <h1 
              className="page-back"
              onClick={ () => navigate('/route-detail') }
            > 
              &larr; { t('title.services') } 
            </h1>
          </div>

          <div className="page-section1-title-group">
            <h1 className="page-title"> { serviceZDB.expeditionName } </h1>
            <h1 className="page-title"> { serviceZDB.serviceDate } </h1>
          </div>

          <div className="bloc-tabs">
            <TabButton tab={'details'} descriptionText={ t('tab.details') } toggleState={ toggleState } toggleTab={ toggleTab }  />
            <TabButton tab={'stops'} descriptionText={ t('tab.stops') } toggleState={ toggleState } toggleTab={ toggleTab } />
            {/* <TabButton tab={'bookings'} descriptionText={ t('tab.bookings') } toggleState={ toggleState } toggleTab={ toggleTab } /> */}
            {
              activeService
                ?
                  <>
                    <TabButton tab={'location'} descriptionText={ t('tab.location') } toggleState={ toggleState } toggleTab={ toggleTab } />
                  </>
                :
                  <>

                    <TabButton tab={'use-service'} descriptionText={ t('tab.use_service') } toggleState={ toggleState } toggleTab={ toggleTab } />
                  </>
            }
          </div>

        </section>
    
        <section className="page-section2-container">

          { toggleState === 'details' && <ServiceZDBDetails /> }
          { toggleState === 'stops' && <WaypointSectionList /> }
          { toggleState === 'location' && <LocationServiceSection /> }
          {/* { toggleState === 'bookings' && <BookingsServiceSection /> } */}
          { toggleState === 'use-service' && <UseServiceSection /> }
            
        </section>   
       
      </div>
  )
}
