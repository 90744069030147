//***************************************
 //
 // Creado por: Miguel Sánchez
 // 
 //****************************************

import { useContext, useState } from 'react';

import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/auth/AuthContext';
import { ClientContext } from '../../context/client/ClientContext';
import { PassengerContext } from "../../context/passenger/PassengerContext";
import { CancelButton } from "../buttons/CancelButton";
import { SaveButton } from "../buttons/SaveButton";
import { FormInput } from "../inputs/FormInput";
import { CustomModal } from './CustomModal';
import { CreatePassengerResponse, RoleStatus } from '../../interfaces/appInterfaces';


interface Props {
    modalState: {
        title: string;
        description: string;
    };
    onCloseModal: () => void;
    [x: string]: any;
}

export const CreatePassengerModal = ( { modalState, onCloseModal } : Props  ) => {

    const { t } = useTranslation('global');
    const { passengerCreate } = useContext(PassengerContext);
    const { user } = useContext(AuthContext);
    const { clientZD } = useContext(ClientContext);

    const [ modalErrorState, setModalErrorState ] = useState({
        title: '',
        description: '',
        visible: false,
        navigation: true,
        activityIndicator: false,
      });

    const onCloseModalError = () => {
        setModalErrorState({
          title: '',
          description: '',
          visible: false,
          navigation: true,
          activityIndicator: false,
        });
    }

    return (
        <>
        <div className="darkBG" onClick={() => { onCloseModal() }} />
        <div className="centered">
            <div className="modal-big">
                <div className="modal-big-header">
                    <h5 className="modal-big-heading"> { modalState.title } </h5>
                </div>
                <div className="modal-big-content">
                  
                    <Formik                
                        initialValues={{
                            email: '',
                            nif: '',
                            phone: '',
                            fullname: '',
                            password: '',
                            password2: '',
                            actived: false,
                        }}
                        onSubmit={ async({ fullname, nif, phone, email, password, actived }) => {  

                            let resp = {} as CreatePassengerResponse;

                        //     user?.rol_web === RoleStatus.Client
                        //     ? resp = await passengerCreate({ email, nif, phone, password, fullname, actived, codigo: user.codeCompany })
                        //     : resp = await passengerCreate({ email, nif, phone, password, fullname, actived, codigo: clientZD?.codigo });
                           
                        //    if ( resp.msg === 'ok' ) { 
                        //         onCloseModal();
                        //    } else {
                       
                        //         setModalErrorState({
                        //             title: t('title.error'),
                        //             description: resp.msg,
                        //             visible: true,
                        //             navigation: true,
                        //         });

                        //    }
                        }}
                        validationSchema={ 

                            Yup.object({
                                fullname: Yup.string()                               
                                            .required( t('validation_error.required') ),
                                nif: Yup.string()                               
                                            .required( t('validation_error.required') ),
                                phone: Yup.string()                               
                                        .required( t('validation_error.required') ),
                                email: Yup.string()
                                        .email( t('validation_error.email_not_valid') )
                                        .required( t('validation_error.required') ),
                                email2: Yup.string()
                                            .required(t('validation_error.required'))
                                            .oneOf([Yup.ref('email'), null],  t('validation_error.emails_coincide')),             
                                password: Yup.string()
                                            .matches(/\w*[a-z]\w*/,  t('validation_error.password_a_char_min') )
                                            .matches(/\w*[A-Z]\w*/,  t('validation_error.password_a_char_max') )
                                            .matches(/\d/, t('validation_error.password_contains_num') )
                                            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, t('validation_error.password_contains_char_special') )
                                            .min(8,({ min }) => t('validation_error.password_contains_min_char'))
                                            .required(t('validation_error.required')),          
                                password2: Yup.string()
                                            .required( t('validation_error.required') )
                                            .oneOf([Yup.ref('password'), null], t( 'validation_error.passwords_coincide') ),  
                                actived: Yup.boolean()            
                            
                                        
                            })
                        }
                    >
                        { ({ handleSubmit, isValid, dirty }) => (
                            < div className="create-use-client-form" >

                                <h2 className="modal-big-section-title">  { t('title.general_data') } </h2>

                                <Field
                                    component={ FormInput }
                                    label={ t('label.email') }
                                    name="email"
                                    type="email"
                                    placeholder={ t('placeholder.email') }
                                />

                                <Field
                                    component={ FormInput }
                                    label={ t('label.repeat_email') }
                                    name="email2"
                                    type="email"
                                    placeholder={ t('placeholder.email') }
                                />

                                <Field
                                    component={ FormInput }
                                    label={ t('label.nif') }
                                    name="nif"
                                    type="text"
                                    placeholder={ t('placeholder.nif') }
                                />        
                                
                                <Field
                                    component={ FormInput }
                                    label={ t('label.phone') }
                                    name="phone"
                                    type="text"
                                    placeholder={ t('placeholder.phone') }
                                />        

                                <Field
                                    component={ FormInput }
                                    label={ t('label.fullname') }
                                    name="fullname"
                                    type="text"
                                    placeholder={ t('placeholder.fullname') }
                                />

                                <Field
                                    component={ FormInput }
                                    label={ t('label.password') } 
                                    name="password"
                                    type="password"
                                    placeholder={ t('placeholder.password') } 
                                />

                                <Field
                                    component={ FormInput }
                                    label={ t('label.repeat_password') } 
                                    name="password2"
                                    type="password"
                                    placeholder={ t('placeholder.password') } 
                                />
            
                                <h2 className="modal-big-section-title">{ t('title.attributes') }</h2>

                                <label className="modal-big-section-checkbox">
                                    <Field type="checkbox" name="actived" />
                                        { t('label.activate') }
                                </label>
                       

                                <CancelButton description={ t('button.cancel') } handleCancel={ onCloseModal } />

                                <SaveButton description={ t('button.save') } isValid={ isValid } dirty={ dirty } handleSubmit={ handleSubmit } ClassName="save-button" />
                            
                            </div>
                        )
                    }
                    </Formik>

                </div>
        
            </div>
            
        </div>
        { modalErrorState.visible && <CustomModal modalState={ modalErrorState } onCloseModal={ onCloseModalError }/>}
    </>
    )
}
