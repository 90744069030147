 //***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
interface Props {
    descriptionButton: string;
    classname: string;
    handleConfirmAction: () => void;
}

export const ActionButton = ( { descriptionButton, handleConfirmAction, classname }: Props) => {
  return (
    <button
      className={ classname } 
      onClick={ () => handleConfirmAction() }
    >
      { descriptionButton }
    </button>
  )
}
