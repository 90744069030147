import { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { RoleStatus, RouteZD } from '../../interfaces/appInterfaces';
import { AuthContext } from '../../context/auth/AuthContext';
import { OrderSelect } from '../selects/OrderSelect';
import { ActionModal } from '../modals/ActionModal';
import { RouteContext } from '../../context/route/RouteContext';
import { ClientContext } from '../../context/client/ClientContext';
import { ContentRouteGridZD } from '../lists/ContentRouteGridZD';

const INITIAL_PAGE = 1;
const MAX_ITEMS = 10;

export const RouteSectionListZD = () => {
    
  const { t } = useTranslation('global');  
  const { routeListZD, routesZD, sortByProperty } = useContext(RouteContext);
  const { user, token } = useContext(AuthContext);
  
  const { clientZD } = useContext(ClientContext);
  const [ loading, setLoading ] = useState(true);

  const [ modalActionState, setModalActionState ] = useState({
    title: '',
    description: '',
    subtitle: '',
    itemId: '',
    visible: false,
    action: ''
  });
  const [ itemsGrid, setItemsGrid ] = useState<Array<RouteZD> | undefined>([]);
  const [ pageState, setPageState ] = useState(INITIAL_PAGE);

  const orderByList = [ 
    {
      value: 'idroute',
      text: t('column.code')
    },  
    {
        value: 'name',
        text: t('column.name')
    }
  ];

  const columnsRouteList = [ 
    {
      value: 'codigo',
      text: t('column.code')
    },
    {
      value: 'name',
      text:  t('column.name')
    },
    {
      value: 'void1',
      text:  ''
    }

  ];

  const handleOrderBy = async( value: string ) => {
    setPageState(INITIAL_PAGE);
    setLoading(true); 
    sortByProperty( value );
    routesZD && setItemsGrid( routesZD!.slice( 0, 10 ) );
    setLoading(false);
  }
  
  const handleNextPage = async() => {
    setPageState( pageState + 1);
    const from = (pageState + 1) * 10 - 10;
    routesZD && setItemsGrid( routesZD!.slice( from, from + 10 ) );
  }

  const handlePreviousPage = () => {
      setPageState( pageState - 1);
      const from = (pageState - 1) * 10 - 10;
      routesZD && setItemsGrid( routesZD!.slice( from, from + 10 ) );
  }

  const onCloseModalAction = () => {
    setModalActionState({
      title: '',
      description: '',
      subtitle: '',
      itemId: '',
      visible: false,
      action: '',
    });
  }

  useEffect( () => {
    setLoading(true); 
    const axiosData = async () => {
      // user?.rol_web === RoleStatus.Client 
      //   ? await routeListZD( { token: token!  } )
      //   : await routeListZD( { empresa: 247, v2:1, codigo: clientZD?.codigo } );
      await routeListZD( { token: token! } )
    }
    axiosData()
      .catch(console.error);

    setLoading(false);    
  }, []);

  useEffect(() => {
      setLoading(true); 
      routesZD && setItemsGrid( routesZD!.slice( 0, 10 ) );
      setLoading(false);
  }, [ routesZD ]);


  return (
      <section className="page-section2-container">

        <div className="page-section2-search-group">
          <OrderSelect handleOrderBy={ handleOrderBy } options={ orderByList }/>
        </div>

        <div>
          <div className="content-tabs-3-columns">
            {
              columnsRouteList.map( ( column, index ) =>  (
                <div key={ column.value }className={ `grid-header${index+1}`}>{ column.text }</div>
              ))
            }
            {
              ( loading )
              ? <div className="lds-dual-ring grid-loading"></div>
              : ( itemsGrid?.length !== 0 ) 
                ? itemsGrid!.map( ( item, index ) => (
                    <ContentRouteGridZD key={ item.id } item={ item } index={ index } /> 
                    ))
                : <div className="no-results-grid"><h1>{ t('description.no_results') }</h1></div>
            }
          </div>
        </div>
                    
        <div>
          {
            pageState !== 1 &&  
              <button 
                className="next-page-button"   
                onClick={ handlePreviousPage }    
              >
                &lt;
              </button>             
          }
          
          <button 
            className="page-button"       
          >
            { pageState }
          </button>
          <button 
            className={
                itemsGrid!.length < MAX_ITEMS
                    ? "next-page-button-disabled"
                    : "next-page-button"
              }
            onClick={ handleNextPage }   
            disabled={ itemsGrid!.length < MAX_ITEMS }      
          >
            &gt;
          </button>

        </div>
  
        { modalActionState.visible && <ActionModal modalActionState={ modalActionState }  onCloseModalAction={ onCloseModalAction }/>}
      </section>              
  )
}
