//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { ClientZD } from "../../interfaces/appInterfaces";


export interface ClientState {
    clientZD: ClientZD | null;
    clientsZD: Array<ClientZD> | null,
}

type ClientAction = 
    | { type: 'selected', payload: { client: ClientZD } }
    | { type: 'not-selected' }
    | { type: 'empty-list-client-zd' }
    | { type: 'list-client-zd', payload: { clientsZD: Array<ClientZD> } }

 export const clientReducer = ( state: ClientState, action: ClientAction ): ClientState => {

        switch (action.type) {
            case 'selected':
                return {
                    ...state,
                    clientZD: action.payload.client,
                }
        
            case 'not-selected':
                return {
                    ...state,
                    clientZD: null,
                }

            case 'empty-list-client-zd':
                return {
                    ...state,
                    clientsZD: null,
                }
        
            case 'list-client-zd':
                return {
                    ...state,
                    clientsZD: action.payload.clientsZD,
                }

            default:
               return state;
        }
}