//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { RiRouteLine } from 'react-icons/ri';
import { VscGraph } from 'react-icons/vsc';
import { FaUsersCog, FaFileInvoiceDollar } from 'react-icons/fa';
import { BsMegaphoneFill } from 'react-icons/bs';

import { ClientRoute } from './ClientRoute';
import { AdminRoute } from './AdminRoute';
import { SideBarMenuItem, SideBarMenuCard } from '../types/types';
import { AuthContext } from '../context/auth/AuthContext'
import { ClientsPage } from '../pages/admin-pages/ClientsPage';
import { ClientDetailPage } from '../pages/admin-pages/ClientDetailPage';
import { PassengersPage } from '../pages/clients-pages/PassengerPage';
import { InvoicingPage } from '../pages/clients-pages/InvoicingPage';
import { PassengerDetailPage } from '../pages/clients-pages/PassengerDetailPage';
import { StatisticsPage } from '../pages/common-pages/StatisticsPage';
import { UsersAccessPage } from '../pages/common-pages/UsersAccessPage';
import { NoticesPage } from '../pages/common-pages/NoticesPage';
import { RoutesPage } from '../pages/clients-pages/RoutesPage';
import { SideBarMenu } from '../components/ui/SideBarMenu';
import { TopBarMenu } from '../components/ui/TopBarMenu';
import { RouteDetailPage } from '../pages/common-pages/RouteDetailPage';

import { InvoiceDetailPage } from '../pages/clients-pages/InvoiceDetailPage';
import { ServiceZDBDetailPage } from '../pages/common-pages/ServiceZDBDetailPage';
import { RoleStatus } from '../interfaces/appInterfaces';

export const ZDBusBooking = () => {

  const { t } = useTranslation('global');
  const { user } = useContext(AuthContext);

  let items = [] as SideBarMenuItem[];

  const itemsClient: SideBarMenuItem[] = [
      // {
      //   id: '1',
      //   label: t('sidebar.statistics'),
      //   icon: () => <VscGraph size="36px"/>,
      //   url: '/statistics',
      // },
      {
        id: '1',
        label: t('sidebar.contracted_routes'),
        icon: () => <RiRouteLine size="36px"/>,
        url: '/routes',
      },
      {
        id: '2',
        label: t('sidebar.passengers'),
        icon: () => <HiOutlineUserGroup size="36px"/>,
        url: '/passengers',
      },


      // {
      //   id: '4',
      //   label: t('sidebar.users_access'),
      //   icon: () => <FaUsersCog size="36px"/>,
      //   url: '/users-access',
      // },
      // {
      //   id: '5',
      //   label: t('sidebar.invoicing'),
      //   icon: () => <FaFileInvoiceDollar size="36px"/>,
      //   url: '/invoicing',
      // },
      // {
      //   id: '6',
      //   label: t('sidebar.notices'),
      //   icon: () => <BsMegaphoneFill size="36px"/>,
      //   url: '/notices',
      // },
    ];

  const itemsAdmin: SideBarMenuItem[] = [
    // {
    //   id: '1',
    //   label: t('sidebar.statistics'),
    //   icon: () => <VscGraph size="36px"/>,
    //   url: '/statistics',
    // },
    {
      id: '2',
      label: t('sidebar.clients'),
      icon: () => <HiOutlineUserGroup size="36px"/>,
      url: '/clients',
    },

    // {
    //   id: '3',
    //   label: t('sidebar.users_access'),
    //   icon: () => <FaUsersCog size="36px"/>,
    //   url: '/users-access',
    // },
    // {
    //   id: '4',
    //   label: t('sidebar.notices'),
    //   icon: () => <BsMegaphoneFill size="36px"/>,
    //   url: '/notices',
    // },
  ];

  const card: SideBarMenuCard = {
    id: 'card01',
    displayName: 'Reservas ZDBUS',
    title: '',
    url: '/'
  }

  switch (user?.rol_web) {
    case RoleStatus.Client:
      items = itemsClient
      break;
    case RoleStatus.Admin:
      items = itemsAdmin
      break;
    default:
      break;
  }
      
  return (
      <>
        <div className='main-layout'>

          <SideBarMenu items={ items } card={ card } />
  
          <div className='main-content'>
            <TopBarMenu />

            <main>

              <Routes>
                  {/* <Route path="users-access" element={ <UsersAccessPage /> } />  */}
                  {/* <Route path="statistics" element={ <StatisticsPage /> } />   */}
                  <Route path="serviceZDB-detail" element={ <ServiceZDBDetailPage /> } /> 
                  {/* <Route path="notices" element={ <NoticesPage /> } /> */}
                  <Route path="route-detail" element={ <RouteDetailPage /> } />
                  <Route path="passenger-detail" element={ <PassengerDetailPage /> } />
                  {/* <Route path="invoice-detail" element={ <InvoiceDetailPage /> } /> */}

                  <Route path="routes" element={ 
                      <ClientRoute>
                        <RoutesPage /> 
                      </ClientRoute>
                  } />
                  
                  <Route path="passengers" element={
                    <ClientRoute>
                      <PassengersPage />
                    </ClientRoute>
                  } />

                  {/* <Route path="invoicing" element={
                      <ClientRoute>
                        <InvoicingPage />
                      </ClientRoute>
                  } /> */}
                  
                  {/* <Route path="clients" element={
                      <AdminRoute>
                        <ClientsPage />
                      </AdminRoute>
                  } /> */}
{/* 
                  <Route path="client-detail" element={
                    <AdminRoute>
                      <ClientDetailPage />
                    </AdminRoute>
                  } /> */}

                  <Route path="/*" element={<RoutesPage />} />
              </Routes>
            </main>
          </div>
          
        </div>
      </>
  )
}
