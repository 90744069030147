import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IoMdNotifications } from 'react-icons/io';
import { MdLogout } from 'react-icons/md';

import { AuthContext } from "../../context/auth/AuthContext";
import { LanguageSwitch } from "../selects/LanguageSwitch";

export const TopBarMenu = () => {
  
    const { t, i18n } = useTranslation('global');
    const { user, logOut } = useContext(AuthContext);
    const [ value, setValue ] = useState(localStorage.getItem('language') || 'es');

    const options = [
      {
        value: 'es',
        text: t('select_options.spanish')
      },
      {
        value: 'ca',
        text: t('select_options.catalan')
      },
      {
        value: 'en',
        text: t('select_options.english')
    }
  ]

  const handleLanguageChange = async( value: string ) => {
    setValue(value);
    i18n.changeLanguage(value);
    await localStorage.setItem( 'language', value );
  }


    return (
      <div className="block-navbar">     
       <nav className="topbar">
          <LanguageSwitch value={value} onChange={handleLanguageChange}>
          {
            options.map( (option) => (
              <option value={ option.value } key={option.value}>{ option.text }</option>
            ))
          }
          </LanguageSwitch>
          {/* <IoMdNotifications className="icon-notification"/> */}
          {/* TODO: Revisar */}
          {/* <div className="username-label"><b>{ t('label.code') }: </b>{ user?.codeCompany }</div> */}
          <div className="username-label"><b>{ t('label.username') }: </b>{ user?.name }</div>
          <MdLogout 
            className="icon-logout"
            onClick={ () => logOut() }
          />
        </nav>
        <hr className="topbar-hr"/>

      </div>

    );
};


    

