//***************************************
 //
 // Creado por: Miguel Sánchez
 // 
 //****************************************
 
import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ServiceZDBContext } from '../../context/serviceZDB/ServiceZDBContext';
import { LoadingPage } from '../../pages/loading/LoadingPage';
import { getDateTimeStamp } from '../../util/utils';
import { MapView } from '../MapView';
import { AuthContext } from '../../context/auth/AuthContext';

export const LocationServiceSection = () => {

  const { t } = useTranslation('global');
  const { token } = useContext( AuthContext );
  const { serviceZDB, driverInfo, getGPS, getServiceInfo } = useContext( ServiceZDBContext );
  const [ isActive , setIsActive ] = useState( true );
  
  const firstStopTime = getDateTimeStamp( serviceZDB!.serviceDate ,serviceZDB!.stops[0].waypointTime ) - (30 * 60000);
  const lastStopTime = getDateTimeStamp( serviceZDB!.serviceDate ,serviceZDB!.stops[ serviceZDB!.stops.length - 1 ].waypointTime ) + (30 * 60000);


  useEffect(() => {
    const axiosData = async () => {
      await getGPS( {  token: token! ,serviceId: serviceZDB!.serviceId, mediaSpeedMin: 5, skipTimeRestriction: 0 } );
      await getServiceInfo( { serviceId: serviceZDB!.serviceId } );
    }    
    axiosData()
      .catch(console.error);
  }, []);

  useEffect(() => {
      if ( new Date().getTime() <= lastStopTime && new Date().getTime() >= firstStopTime ){
        setIsActive( true )
      } else { 
        setIsActive( false ) ;
      }
  }, []);
  
  // if( !driverInfo ) return <LoadingPage />

  return (
    <section className="section-map" >
      { 
        isActive && driverInfo
          ? <MapView />
          : 
              <div className="container-alert-service">
                <p>
                  { t('description.service_not_active') }
                </p>
              </div> 
      }
 
    </section>
  )
}