//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { Notice } from "../../interfaces/appInterfaces";


export interface NoticeState {
    notice: Notice | null;
}

type NoticeAction = 
    | { type: 'selected', payload: { notice: Notice } }
    | { type: 'not-selected' }

 export const noticeReducer = ( state: NoticeState, action: NoticeAction ): NoticeState => {

        switch (action.type) {
            case 'selected':
                return {
                    ...state,
                    notice: action.payload.notice,
                }
        
            case 'not-selected':
                return {
                    ...state,
                    notice: null,
                }

            default:
               return state;
        }
}