//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from "../context/auth/AuthContext";


export const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {

    const { status } = useContext(AuthContext);
    const { pathname, search } = useLocation();
    
    localStorage.setItem('lastPath', pathname + search );
    
    return status === 'authenticated'
        ? children
        : <Navigate to="/login" />
}
