//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext, useState } from 'react';

import { useNavigate, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PassengerContext } from '../../context/passenger/PassengerContext';
import { TabButton } from '../../components/buttons/TabButton';
import { PassengerDetails } from '../../components/tabs/detail-passenger/PassengerDetails';
import { RouteSectionListZD } from '../../components/section/RouteSectionListZD';
import { AuthContext } from '../../context/auth/AuthContext';
import { RoleStatus } from '../../interfaces/appInterfaces';


export const PassengerDetailPage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const { passenger } = useContext(PassengerContext);
  const { user } = useContext(AuthContext);

  const [ toggleState, setToggleState ] = useState('details');

  const toggleTab = (tab: string) => {
    setToggleState(tab);
  };

  return (
    passenger === null 
    ?   <Navigate to="/passengers" />
    : 

      <div className="page-container">
        <section className="page-section1-container">

          <div className="page-section1-back-group" >
            <h1 
              className="page-back"
              onClick={ () => {
                user?.rol_web === RoleStatus.Client  
                  ? navigate('/passengers')
                  : navigate('/client-detail')
              }}
            > 
              &larr; { t('title.passengers') }
            </h1>
          </div>

          <div className="page-section1-title-group">
            <h1 className="page-title"> { passenger?.name } </h1>
          </div>

          <div className="bloc-tabs">

            <TabButton tab={'details'} descriptionText={ t('tab.details') } toggleState={ toggleState } toggleTab={ toggleTab }  />
            <TabButton tab={'rutas'} descriptionText={ t('tab.routes') } toggleState={ toggleState } toggleTab={ toggleTab } />
            {/* <TabButton tab={'reservations'} descriptionText={ t('tab.reservations') } toggleState={ toggleState } toggleTab={ toggleTab }  /> */}

          </div>

        </section>
    
        <section className="page-section2-container">

          { toggleState === 'details' && <PassengerDetails /> }
          { toggleState === 'rutas' && <RouteSectionListZD /> }
            
        </section>   
       
      </div>
  )
}
