//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { PassengerContext } from "../../context/passenger/PassengerContext";
import { NoticeContext } from '../../context/notice/NoticeContext';
import { RouteContext } from '../../context/route/RouteContext';
import { ActionButton } from '../buttons/ActionButton';
import { CustomModal } from './CustomModal';
import { CancelButton } from "../buttons/CancelButton";
import { UserContext } from '../../context/user/UserContext';
import { AuthContext } from '../../context/auth/AuthContext';
import { DeletePassengerResponse, ResponseStatus } from '../../interfaces/appInterfaces';


interface Props {
    modalActionState: {
        title: string;
        subtitle: string;
        itemId: any;
        description: string;
        action: string;
    };
    onCloseModalAction: () => void;
    [x: string]: any;
}

export const ActionModal = ( { modalActionState, onCloseModalAction } : Props  ) => {

    const { t } = useTranslation('global');

    const { token } = useContext(AuthContext);
    const { passengerDelete } = useContext(PassengerContext);
    // const { userDelete } = useContext(UserContext);
    // const { routeQuitToPassenger } = useContext(RouteContext);
    // const { noticeDelete } = useContext(NoticeContext);

    const [ modalErrorState, setModalErrorState ] = useState({
        title: '',
        description: '',
        visible: false,
        navigation: true,
        activityIndicator: false,
      });

    
    const onCloseModalError = () => {
        setModalErrorState({
          title: '',
          description: '',
          visible: false,
          navigation: true,
          activityIndicator: false,
        });
    }

    const handleConfirmAction = async() => {

        let resp = {} as DeletePassengerResponse;

        switch ( modalActionState.action ) {
      
            // case 'delete-user':
            //     resp = await userDelete(modalActionState.itemId);
            //     break;
            case 'delete-passenger':
                resp = await passengerDelete({ token: token!, id: modalActionState.itemId });
                break;
            // case 'accept-passenger':
            //     resp = await passengerAccept(modalActionState.itemId);
            //     break;
            // case 'deny-passenger':
            //     resp = await passengerDeny(modalActionState.itemId);
            //     break;
            // case 'quit-route':
            //     resp = await routeQuitToPassenger( { routeId: modalActionState.itemId, passengerId: passenger!.id } );
            //     break;
            // case 'delete-notice':
            //     resp = await noticeDelete(modalActionState.itemId);
            //     break;
            default:
                break;
        }

        if ( resp.status ===  ResponseStatus.Ok ) { 
             onCloseModalAction();
        }

    }

    return (
        <>
            <div className="darkBG" onClick={() => { onCloseModalAction() }} />
            <div className="centered">
                <div className="modal-small">
                    <div className="modal-big-header">
                        <h5 className="modal-big-heading"> { modalActionState.title } </h5>
                    </div>
                    <div className="modal-big-content">
                        <h1 className="modal-big-subheader">{ modalActionState.subtitle }</h1>
                        <p >{ modalActionState.description }</p>                 
                        <CancelButton description="Cancelar" handleCancel={ onCloseModalAction } />
                        {/* { modalActionState.action === 'delete-user'  && <ActionButton descriptionButton={ t('button.delete') } classname={ 'delete-medium-button' } handleConfirmAction={ handleConfirmAction }/> } */}
                        { modalActionState.action === 'delete-passenger'  && <ActionButton descriptionButton={ t('button.delete') } classname={ 'delete-medium-button' } handleConfirmAction={ handleConfirmAction }/> }
                        {/* { modalActionState.action === 'accept-passenger' && <ActionButton descriptionButton={ t('button.accept') } classname={ 'accept-medium-button' } handleConfirmAction={ handleConfirmAction }/> }
                        { modalActionState.action === 'deny-passenger' && <ActionButton descriptionButton={ t('button.deny') } classname={ 'delete-medium-button' } handleConfirmAction={ handleConfirmAction }/> }
                        { modalActionState.action === 'quit-route'  && <ActionButton descriptionButton={ t('button.quit') } classname={ 'delete-medium-button' } handleConfirmAction={ handleConfirmAction }/> }
                        { modalActionState.action === 'delete-notice'  && <ActionButton descriptionButton={ t('button.delete') } classname={ 'delete-medium-button' } handleConfirmAction={ handleConfirmAction }/> } */}
        
                    </div>
                </div>
            </div>
            { modalErrorState.visible && <CustomModal modalState={ modalErrorState } onCloseModal={ onCloseModalError }/>}
        </>
    )
}
