//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RouteZD } from '../../interfaces/appInterfaces';
import { RouteContext } from '../../context/route/RouteContext';
import { PassengerContext } from '../../context/passenger/PassengerContext';
import { CustomModal } from '../modals/CustomModal';
import { ActionModal } from '../modals/ActionModal';

interface Props {
    item: RouteZD;
    index: number;
}

export const ContentRouteGridZD = ( { item, index }: Props ) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('global');

  const { passenger } = useContext( PassengerContext );
  const { routeSelectedZD, routeCheck, routeAddToPassenger } = useContext(RouteContext);
  
  const [ addedRoute, setAddedRoute ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ modalErrorState, setModalErrorState ] = useState({
    title: '',
    description: '',
    visible: false,
    navigation: true,
    activityIndicator: false,
  });
  const [ modalActionState, setModalActionState ] = useState({
    title: '',
    description: '',
    subtitle: '',
    itemId: '',
    visible: false,
    action: ''
  });

  const handleQuit = ( itemId: number ) => {
    setModalActionState({
      title: t('title.confirmation_delete'),
      subtitle: t('subtitle.you_sure'),
      itemId: itemId.toString(),
      description: t('description.confirm_route_delete'),
      visible: true,
      action: 'quit-route'
    });
  }

  const onCloseModalError = () => {
    setModalErrorState({
      title: '',
      description: '',
      visible: false,
      navigation: true,
      activityIndicator:false,
    });
  }

  const onCloseModalAction = () => {
    setModalActionState({
      title: '',
      description: '',
      subtitle: '',
      itemId: '',
      visible: false,
      action: '',
    });
  }

  const handleDetail = async( route: RouteZD ) => {
    await routeSelectedZD(route);
    navigate("/route-detail", { 
      replace: true, 
    });
  };

  // const addRouteZD = async( routeId: number ) => {

  //   const passengerId = passenger!.id;

  //   const resp = await routeAddToPassenger({ passengerId, routeId });
  //   if ( resp.msg === 'ok' ) { 
  //     setAddedRoute( true );  
  //   } else {
  //    setModalErrorState({
  //        title: 'Error',
  //        description: resp.msg,
  //        visible: true,
  //        navigation: true,
  //        activityIndicator: false,
  //    });

  //   }
  // };

  useEffect( () => {
    // setLoading(true); 
    // const axiosData = async () => {
    //   const resp = await routeCheck( item.id, passenger!.id )

    //   if( resp.found === true) {
    //     setAddedRoute( true );  
    //   } else {
    //     setAddedRoute( false );  
    //   }
    //   setLoading(false);
    // }
    // axiosData()
    //   .catch(console.error);
  
 
  }, [ modalActionState ]);
  

  return (
    <>
      <div className={ index%2!==0 ? "grid-columns-3-content1 grey-background " : "grid-columns-3-content1" }>{ item.id }</div>
      <div className={ index%2!==0 ? "grid-columns-3-content2 grey-background " : "grid-columns-3-content2" }>{ item.routeName }</div>
      <div className={ index%2!==0 ? "grid-columns-3-content3 grey-background " : "grid-columns-3-content3" }>
      {
        (location.pathname !== '/passenger-detail') &&
          <button 
            className="details-button"
            onClick={ () => handleDetail(item) }
          >
              { t('button.details') }
          </button>
        
      }
      {/* {
        ( location.pathname === '/passenger-detail' && addedRoute === true ) &&
          <button 
              className="delete-button"
              onClick={ () =>  handleQuit(item.id) }
          >
            { t('button.quit') }
          </button>
      }
      {  
        ( location.pathname === '/passenger-detail' && addedRoute === false ) &&
            <button 
              className="accept-button"
              onClick={ () =>  addRouteZD(item.id) }
            >
              { t('button.add') }
            </button>
      } */}
      </div>
      { modalErrorState.visible && <CustomModal modalState={ modalErrorState } onCloseModal={ onCloseModalError }/>}
      { modalActionState.visible && <ActionModal modalActionState={ modalActionState }  onCloseModalAction={ onCloseModalAction }/>}
    </>
  )
}


