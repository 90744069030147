//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 

import { createContext } from 'react';

type PlacesContextProps = {
    isLoading: boolean;
    driverLocation?: [ number, number ];
    getDriverLocation: ( ) => void;
}

export const PlacesContext = createContext({} as PlacesContextProps);

