//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { Booking } from "../../interfaces/appInterfaces";


export interface BookingState {
    errorMessage: string;
    booking: Booking | null;
}

type BookingAction = 
    | { type: 'selected', payload: { booking: Booking } }
    | { type: 'not-selected' }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'clearState' }

 export const bookingReducer = ( state: BookingState, action: BookingAction ): BookingState => {

        switch (action.type) {
            case 'selected':
                return {
                    ...state,
                    booking: action.payload.booking,
                }
        
            case 'not-selected':
                return {
                    ...state,
                    booking: null,
                }
            case 'addError':
                return {
                    ...state,
                    errorMessage: action.payload
                }
            case 'removeError':
                return {
                    ...state,
                    errorMessage: '',
                }

            case 'clearState':
                return {
                    errorMessage: '',
                    booking: null,
         
                }

            default:
               return state;
        }
}