//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext } from "react";

import { useTranslation } from 'react-i18next';

import { AuthContext } from "../../context/auth/AuthContext";
import { ModalState } from "../../interfaces/appInterfaces";

interface Props {
    modalState: ModalState;
    [x: string]: any;
}

export const CustomModal = ( props: Props  ) => {

    const { t } = useTranslation('global');
    const { modalState, onCloseModal } = props;
    const { removeError } = useContext( AuthContext );

    return (
        <>
        <div 
            className="darkBG" 
            onClick={() => {
                            modalState.navigation === true 
                                ? onCloseModal()
                                : removeError()
                            }} />
        <div className="centered">
            <div className="modal">
                <div className="modalHeader">
                    <h5 className="heading"> { modalState.title } </h5>
                </div>
                <div className="modalContent">
                    { modalState.description }
                </div>
                {modalState.activityIndicator ? (
                            <div className="modal-wait-container">
                                <div className="lds-dual-ring grid-loading"></div>
                            </div>
                        ) : ( 
                            <div className="modalActions">
                            <div className="actionsContainer">
                                <button
                                    className="acceptBtn"
                                    onClick={() => {
                                            modalState.navigation === true 
                                                ? onCloseModal()
                                                : removeError() 
                                        }
                                    }
                                >
                                { t('button.accept') }
                                </button>
                            </div>
                        </div>
                )}
      
   
            </div>
        </div>
    </>
    )
}
