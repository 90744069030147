import React, { useContext, useState, useEffect } from 'react';
import { ServiceZDBContext } from '../../context/serviceZDB/ServiceZDBContext';
import { AuthContext } from '../../context/auth/AuthContext';
import { PassengerContext } from '../../context/passenger/PassengerContext';
import { IPassengersInServiceResponse, WaypointGrid } from '../../interfaces/appInterfaces';

export const UseServiceSection: React.FC = () => {
  const { token } = useContext(AuthContext);
  const { serviceZDB } = useContext(ServiceZDBContext);
  const { passengersInService } = useContext(PassengerContext);

  const [loading, setLoading] = useState(true);
  const [dataGrid, setDataGrid] = useState<WaypointGrid[]>([]);
  const [expandedWaypoints, setExpandedWaypoints] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !serviceZDB) {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const resp: IPassengersInServiceResponse = await passengersInService({
          token: token,
          id: serviceZDB.serviceId,
        });

        console.log('resp.waypoints:', resp.wayPoints);

        // Mapeo de los waypoints a WaypointGrid
        const gridData: WaypointGrid[] = resp.wayPoints.map((waypoint) => ({
          id: waypoint.idWayPoint,
          address: waypoint.address,
          arrivalTime: extractTime(waypoint.reachTimeAt), // Extraemos solo la hora
          departureTime: extractTime(waypoint.leaveTimeAt),
          countUp: waypoint.pickUp ? waypoint.pickUp.length : 0,
          countDown: waypoint.dropOff ? waypoint.dropOff.length : 0,
          pickup: waypoint.pickUp,
          dropoff: waypoint.dropOff,
        }));

        setDataGrid(gridData);
      } catch (error) {
        console.error('Error fetching passengers in service:', error);
        // Opcional: Manejar el estado de error aquí
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [passengersInService, serviceZDB, token]);

  // Función para manejar el toggle de expansión
  const toggleWaypoint = (id: number) => {
    setExpandedWaypoints((prev) =>
      prev.includes(id) ? prev.filter((waypointId) => waypointId !== id) : [...prev, id]
    );
  };

    const extractTime = (dateTime: string): string => {


      if(dateTime){
        const parts = dateTime.split(' ');
        console.log(parts[1])
          return parts.length > 1 ? parts[1] : '';
      } else {
        return ''
      }

  };

  return (
    <section className="use-service-section">
      <div className="content-section">
        {/* Encabezados del Grid */}
        <div className="grid-headers">
          <div className="header">Parada</div>
          <div className="header">Horario</div>
          <div className="header">Suben</div>
          <div className="header">Bajan</div>
        </div>

        {loading ? (
          <div className="lds-dual-ring grid-loading"></div>
        ) : dataGrid.length === 0 ? (
          <div className="no-results-grid">No hay paradas disponibles.</div>
        ) : (
          dataGrid.map((item, index) => (
            <div key={item.id}>
              {/* Fila de la Parada */}
              <div
                className={`grid-row ${index % 2 !== 0 ? 'grey-background' : ''}`}
                onClick={() => toggleWaypoint(item.id)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleWaypoint(item.id);
                  }
                }}
              >
                {/* Columna de Nombre de la Parada */}
                <div className="cell cell-address">
                <span
                    className={`expand-icon ${
                      expandedWaypoints.includes(item.id) ? 'expanded' : ''
                    }`}
                  >
                    ▼
                  </span>
                  {item.address}

                </div>

                {/* Columna de Horario */}
                <div className="cell cell-schedule">
                  {item.arrivalTime} - {item.departureTime}
                </div>

                {/* Columna de Suben */}
                <div className="cell cell-up">
                  {item.countUp} Suben
                </div>

                {/* Columna de Bajan */}
                <div className="cell cell-down">
                  {item.countDown} Bajan
                </div>
              </div>

              {/* Detalles expandibles */}
              {expandedWaypoints.includes(item.id) && (
                <div className="expanded-details open">
                  <div className="passenger-section">
                    <h4>Suben</h4>
                    {
                    item.pickup &&
                    item.pickup.length > 0 ? (
                      <ul>
                        {item.pickup.map((passenger) => (
                          <li key={passenger.idPass}>
                            <strong>{passenger.name}</strong>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No hay pasajeros que suban en esta parada.</p>
                    )}
                  </div>

                  <div className="passenger-section">
                    <h4>Bajan</h4>
                    
                    {
                      item.dropoff &&
                      item.dropoff.length > 0 ? (
                      <ul>
                        {item.dropoff.map((passenger) => (
                          <li key={passenger.idPass}>
                            <strong>{passenger.name}</strong> ({passenger.reference}) - {passenger.remarks}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No hay pasajeros que bajen en esta parada.</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </section>
  );
};