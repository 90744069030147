//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext } from 'react';

import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';


export const PublicRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const { status } = useContext(AuthContext);

    return status === 'authenticated'
        ? <Navigate to="/statistics" />
        : children
}
