import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Passenger } from '../../interfaces/appInterfaces';
import { PassengerContext } from '../../context/passenger/PassengerContext';


interface Props {
    item: Passenger;
    index: number;
    handleDelete: ( itemId: number ) => void;
}

export const ContentGrid = ( { item, index, handleDelete }: Props ) => {
  
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const { passengerSelected } = useContext(PassengerContext);

  const  handleDetail = ( passenger: Passenger ) => {
    passengerSelected(passenger);
    navigate("/passenger-detail", { 
      replace: true, 
    });
  };

  return (
    <>
    <div className={ index%2 !== 0 ? "grid-content1 grey-background " : "grid-content1" }>{ !item.disabled ? 'Habilitado' : 'Deshabilitado' }</div>
    <div className={ index%2 !== 0 ? "grid-content2 grey-background " : "grid-content2" }>{ item.email }</div>
    <div className={ index%2 !== 0 ? "grid-content3 grey-background " : "grid-content3" }>{ item.name }</div>
    <div className={ index%2 !== 0 ? "grid-content4 grey-background " : "grid-content4" }>{ item.dni }</div>
    <div className={ index%2 !== 0 ? "grid-content5 grey-background " : "grid-content5" }>{ item.phone }</div>
    <div className={ index%2 !== 0 ? "grid-content6 grey-background " : "grid-content6" }>
      <button 
        className="details-button"
        onClick={ () => handleDetail( item ) }
      >
        { t('button.details') }
      </button>
    </div>
    <div className={ index%2 !== 0 ? "grid-content7 grey-background " : "grid-content7" }>
      <button 
        className="delete-button"
        onClick={ () => handleDelete( item.id ) }
      >
          { t('button.delete') }
      </button>
    </div>
  </>
  )
}
