
import { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {  ExpeditionZD, GroupExpeditionZD, ServiceZDB, WaypointZD } from '../../interfaces/appInterfaces';
import { checkOutDate, getDateTimeStamp, getDateTimeStamp2, getTimeStamp } from '../../util/utils';
import { OrderSelect } from '../selects/OrderSelect';
import { RouteContext } from '../../context/route/RouteContext';
import { ContentServiceGrid } from '../lists/ContentServiceGrid';

const INITIAL_PAGE = 1;
const MAX_ITEMS = 10;

export const ServiceSectionList = () => {
    
  const { t } = useTranslation('global');  
  const { routeZD, getRouteDays, getRouteServices } = useContext(RouteContext);
  const [ loading, setLoading ] = useState(true);

  const [ serviceList, setServiceList ] = useState<ServiceZDB[]>([]);
  const [ itemsGrid, setItemsGrid ] = useState<ServiceZDB[]>([]);
  const [ pageState, setPageState ] = useState(INITIAL_PAGE);
  const [ orderState, setOrderState ] = useState('date');

  const orderByList = [ 
    {
      value: 'date',
      text: t('column.date')
    },  
    {
        value: 'expedition',
        text: t('column.expedition')
    }
  ];

  const columnsExpeditionList = [ 
    {
      value: 'date',
      text: t('column.date')
    },
    {
      value: 'expedition',
      text:  t('column.expedition')
    },
    {
      value: 'origin',
      text: t('column.origin')
    },
    {
      value: 'destination',
      text: t('column.destination')
    },
    {
      value: 'time',
      text:  t('column.time')
    },

  ];

  const handleOrderBy = async( value: string ) => {
    setPageState(INITIAL_PAGE);
    setLoading(true);
    setOrderState(value);
    let sortedServices: ServiceZDB[] = [];
    switch (value) {
      case 'expedition':
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( a.expeditionName < b.expeditionName ) ? -1 : 1);
          break;

      default:
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
          break;
    }
  
    setItemsGrid( sortedServices.slice( 0, MAX_ITEMS ) );
    setLoading(false);
  }

  const handleNextPage = async() => {
    setPageState( pageState + 1);
    const from = (pageState + 1) * MAX_ITEMS - MAX_ITEMS;
    let sortedServices: ServiceZDB[] = [];
    switch (orderState) {
      case 'expedition':
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( a.expeditionName < b.expeditionName ) ? -1 : 1);
          break;

      default:
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
          break;
    }
    setItemsGrid( sortedServices.slice( from, from + MAX_ITEMS ) );
  }

  const handlePreviousPage = () => {
      setPageState( pageState - 1);
      const from = (pageState - 1) * MAX_ITEMS - MAX_ITEMS;
      let sortedServices: ServiceZDB[] = [];
      switch (orderState) {
        case 'expedition':
            sortedServices = serviceList;
            sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( a.expeditionName < b.expeditionName ) ? -1 : 1);
            break;
  
        default:
            sortedServices = serviceList;
            sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
            break;
      }
      setItemsGrid( sortedServices.slice( from, from + MAX_ITEMS ) );
  }

  useEffect(() => {
    setLoading(true); 
    const axiosData = async () => {
   
      const resp = await getRouteDays({ routeID: routeZD!.id });
      if( resp.workDays.length > 0 ) {
        const resp2 = await getRouteServices( { routeID: routeZD!.id, pickupDates: resp.workDays  } );
        let countexpeditions = 0; 
        resp2.expeditions.map( ( expedition: ExpeditionZD ) => {
          countexpeditions = countexpeditions + 1;
          let countgroups = 0;
          expedition.groups.map( ( group: GroupExpeditionZD ) =>  {
            countgroups = countgroups + 1;
            group.wayPoints[0].idByDay.map( ( idByDay ) => {

              if ( checkOutDate( idByDay.fecha, group.wayPoints[  group.wayPoints.length -1 ].time ) ) {
                const stops = group.wayPoints.map( ( waypoint: WaypointZD ) => {
                  const waypointReturned = {
                    waypointName: waypoint.wayPointName,
                    waypointId: waypoint.idByDay[0].idparada,
                    waypointLatitude: waypoint.latitude,
                    waypointLongitude: waypoint.longitude,
                    waypointOrder: waypoint.order,
                    waypointTime: waypoint.time,
                  };
                  return waypointReturned;  
                });
    
                setServiceList( prevState => [ ...prevState,  {
                  serviceId: idByDay.idservicio,
                  expeditionName: expedition.expName,
                  serviceDate: idByDay.fecha,
                  stops: stops
                }]);
              }
              
            });
          });
  
        });
      }
       
    }
    axiosData()
      .catch( error => {
        console.log('error1', error )
      })

      setLoading(false);   
  }, []);

  useEffect(() => {
    setLoading(true);  
    let sortedServices = serviceList;
    sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
    setItemsGrid( sortedServices.slice(0,MAX_ITEMS) );
    setLoading(false);  
  }, [serviceList])
  
  return (
    <section className="page-section2-container">

      <div className="page-section2-search-group">
        <OrderSelect handleOrderBy={ handleOrderBy } options={ orderByList }/>
      </div>

      <div>
        <div className="content-tabs">
          {
            columnsExpeditionList.map( ( column, index ) =>  (
              <div key={ column.value }className={ `grid-header${index+1}`}>{ column.text }</div>
            ))
          }
          {
              loading 
                ? 
                  <div className="lds-dual-ring grid-loading"></div>          
                : 
                  itemsGrid.length > 0
                  ? itemsGrid.map( ( item, index ) => (
                    <ContentServiceGrid key={ index } item={ item } index={ index } /> 
                  ))
                  : <div className={ "grid-no-content grey-background" }>{t('description.no_services')}</div>         

          }
        </div>
      </div>
                    
      <div>
        {
          pageState !== 1 &&  
            <button 
              className="next-page-button"   
              onClick={ handlePreviousPage }    
            >
              &lt;
            </button>             
        }
        
        <button className="page-button" >
          { pageState }
        </button>

        {
          (itemsGrid) &&
          <button 
          className={
              itemsGrid!.length < MAX_ITEMS
                  ? "next-page-button-disabled"
                  : "next-page-button"
            }
          onClick={ handleNextPage }   
          disabled={ itemsGrid!.length < MAX_ITEMS }      
        >
          &gt;
        </button>
        }

      </div>
      
    </section>              
  )
}
