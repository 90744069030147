//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useTranslation } from 'react-i18next';

interface Props {
  options: {
    value: string;
    text: string;
  }[];
  handleOrderBy: ( value: string ) => void;
}

export const OrderSelect = ( { handleOrderBy, options }: Props ) => {

  const { t } = useTranslation('global'); 

  return (
    <div className="order-select-component">
        <label className="label"> { t('label.order_by') } </label>
        <select className="select" name="orderBy" id="asd" onChange={ (e) => handleOrderBy( e.target.value ) } >
          {
            options.map( (option) => (
              <option value={ option.value } key={option.value}>{ option.text }</option>
            ))
          }
        </select>
    </div>
  )
}
