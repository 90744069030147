
import { Route, RouteZD } from "../../interfaces/appInterfaces";


export interface RouteState {
    errorMessage: string;
    route: Route | null;
    routesZD: Array<RouteZD> | null,
    routeZD: RouteZD | null;
}

type RouteAction = 
    | { type: 'selected', payload: { route: Route } }
    | { type: 'not-selected' }
    | { type: 'empty-list-route-zd' }
    | { type: 'list-route-zd', payload: { routesZD: Array<RouteZD> } }
    | { type: 'selected-zd', payload: { routeZD: RouteZD } }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'clearState' }


 export const routeReducer = ( state: RouteState, action: RouteAction ): RouteState => {

        switch (action.type) { 

            case 'empty-list-route-zd':
                return {
                    ...state,
                    routesZD: null,
                }
        
            case 'list-route-zd':
                return {
                    ...state,
                    routesZD: action.payload.routesZD,
                }

            case 'selected-zd':
                return {
                    ...state,
                    routeZD: action.payload.routeZD,
                }

            case 'selected':
                return {
                    ...state,
                    route: action.payload.route,
                }
                case 'addError':
                    return {
                        ...state,
                        errorMessage: action.payload
                    }
                case 'removeError':
                    return {
                        ...state,
                        errorMessage: '',
                    }
                case 'clearState':
                    return {
                        errorMessage: '',
                        routesZD: null,
                        routeZD: null,
                        route: null,
                    }
            default:
               return state;
        }
}