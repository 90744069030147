import axios, { AxiosInstance } from "axios";
import zdReservasBusApi from "../api/zdReservasBusApi";

import Keys from "../constants/Keys";


interface EmpresaData {
  adminApi: AxiosInstance;
  DBEmpresa: string;
}
  
export const getEmpresa = async ( empresa: string ): Promise<EmpresaData> => {

  const dataZD = await zdReservasBusApi.post('/GetEmpresa', { JSONString: JSON.stringify({ empresa: empresa, v2: Keys.V2 }) });
  const { urlwsclientes, DBEmpresa } = JSON.parse(dataZD.data.d);

  console.log(urlwsclientes)

  const url = urlwsclientes;
  const adminApi = axios.create({ baseURL: url });

  return { adminApi, DBEmpresa };

};
