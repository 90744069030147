//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext, useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/auth/AuthContext';
import { RouteContext } from '../../context/route/RouteContext';
import { TabButton } from '../../components/buttons/TabButton';
import { RouteDetails } from '../../components/tabs/detail-routes/RouteDetails';
import { ServiceSectionList } from '../../components/section/ServiceSectionList';
import { ServiceDoneSectionList } from '../../components/section/ServiceDoneSectionList';
import { RoleStatus } from '../../interfaces/appInterfaces';

export const RouteDetailPage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const { routeZD } = useContext(RouteContext);
  const { user } = useContext(AuthContext);

  const [ toggleState, setToggleState ] = useState('details');

  const toggleTab = (tab: string) => {
    setToggleState(tab);
  };

  return (
    routeZD === null 
    ?   <Navigate to="/statistics" />
    : 
      <div className="page-container">
        <section className="page-section1-container">

          <div className="page-section1-back-group" >
            <h1 
              className="page-back"
              onClick={ () => {
                user?.rol_web === RoleStatus.Client  
                  ? navigate('/routes')
                  : navigate('/client-detail')
              }}
            > 
              &larr; {t('title.routes') } 
            </h1>
          </div>

          <div className="page-section1-title-group">
            <h1 className="page-title"> {t('title.route') }: { routeZD.routeName } </h1>
          </div>

          <div className="bloc-tabs">
            <TabButton tab={'details'} descriptionText={ t('tab.details') } toggleState={ toggleState } toggleTab={ toggleTab }  />
            {
               ( user?.rol_web === RoleStatus.Admin ) &&
               <>
                <TabButton tab={'price-discounts'} descriptionText={ t('tab.prices_discounts') } toggleState={ toggleState } toggleTab={ toggleTab } />
                <TabButton tab={'operative-parameters'} descriptionText={ t('tab.operative_parameters') } toggleState={ toggleState } toggleTab={ toggleTab }  />
               </>
            }
            <TabButton tab={'services'} descriptionText={ t('tab.services') } toggleState={ toggleState } toggleTab={ toggleTab } />
            <TabButton tab={'services-done'} descriptionText={ t('tab.services_done') } toggleState={ toggleState } toggleTab={ toggleTab } />
          </div>

        </section>
    
        <section className="page-section2-container">
          { toggleState === 'details' && <RouteDetails /> }
          {/* { toggleState === 'price-discounts' && <RoutePriceDiscounts /> } */}
          {/* { toggleState === 'operative-parameters' && <RouteOperativeParameters /> } */}
          { toggleState === 'services' && <ServiceSectionList /> }
          { toggleState === 'services-done' && <ServiceDoneSectionList /> }
        </section>   
       
      </div>
  )
}
