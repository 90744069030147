 //***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

interface Props {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password';
    placeholder?: string;
    [x: string]: any;
}

export const CustomInput = ( props: Props ) => {

    const {
        field: { name, onBlur, onChange, value },
        form: { errors, touched, setFieldTouched },
        ...inputProps
        } = props

    const hasError = errors[name] && touched[name]

    return (
        <div className="login-form-input-component">
            <label className="login-label" htmlFor={`input_${name}`} > { props.label } </label>
            <input 
                className={
                    hasError 
                    ? "input-field error-input"
                    : "input-field"
                  }
                type={ props.type } 
                id={`input_${name}`} 
                placeholder={ props.placeholder } 
                value={ value }
                onChange={ (text) => onChange(name)(text) }
                onBlur={() => {
                    setFieldTouched(name)
                    onBlur(name)
                  }}
                {...inputProps}
            />
            { hasError && <label className="error-text" > { errors[name] } </label> }           
        </div>
    )
}
