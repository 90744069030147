import { User } from "../../interfaces/appInterfaces";


export interface AuthState {
    status: 'checking' | 'authenticated' | 'not-authenticated';
    token: string | null;
    errorMessage: string;
    user: User | null;
    infoMessage: string;
}

type AuthAction = 
    | { type: 'signIn', payload: { token: string, user: User } }
    | { type: 'signUp', payload: string }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'removeMessage' }
    | { type: 'notAuthenticated' }
    | { type: 'logout' }



 export const authReducer = ( state: AuthState, action: AuthAction ): AuthState => {

        switch (action.type) {
            case 'addError':
                return {
                    ...state,
                    user: null,
                    status: 'not-authenticated',
                    token: null,
                    errorMessage: action.payload
                }
        
            case 'removeError':
                return {
                    ...state,
                    errorMessage: '',
                    infoMessage: ''
                }

            case 'removeMessage':
                return {
                    ...state,
                    infoMessage: ''
                }

            case 'signIn':
                return {
                    ...state,
                    errorMessage: '',
                    status: 'authenticated',
                    token: action.payload.token,
                    user: action.payload.user
                }
            case 'signUp':
                return {
                    ...state,
                    errorMessage: '',
                    infoMessage: action.payload,
                    status: 'not-authenticated',
                    token: null,
                    user: null,
            }

            case 'logout':
                return {
                    ...state,
                    errorMessage: '',
                    status: 'not-authenticated',
                    token: null,
                    user: null,
                }
            case 'notAuthenticated':
                return {
                    ...state,
                    errorMessage: '',
                    status: 'not-authenticated',
                    token: null,
                    user: null,
                }

            default:
               return state;
        }
}