




export interface RegisterResponse {
    msg: string;
}


export interface RegisterData {
    codeCompany: string;
    fullname:    string;
    email:       string;
    password:    string;
}

export interface LoginData {
    username:    string;
    password: string;
    role: RoleStatus;
}

export interface SendVerificationCodeData {
    email:            string;
    verificationCode: string;
}

export interface SendVerificationCodeResponse {
    msg:              string;
    email:            string;
    verificationCode: string;
}

export interface RequestResetPasswordResponse {
    msg:   string;
    email: string;
}

export interface ResetPasswordData {
    email:            string;
    verificationCode: string;
    password:         string;
}

export interface ResetPasswordResponse {
    msg: string;
}

export interface CreatePassengerData {
    email:    string;
    nif:      string;
    phone:    string;
    password: string;
    fullname: string;
    actived:  boolean;
    codigo?: string;
}

export interface CreatePassengerResponse {
    msg: string;
}



export interface PassengerRegisteredListData {
    months: number;
}

export interface PassengerRegisteredListResponse {
    passengers?:   Array<Passenger>
}



export interface AcceptPassengerResponse {
    msg: string;
    user: User;
}

export interface Client {
    codeCompany:     string;
    clientName:      string;
    nif:             string;
    address:         string;
    cp:              string;
    city:            string;
    country:         string;
    owner:           string;
    phone:           string;
    uid:             string;
    actived:         boolean;   
}

export interface AddClientData {
    codeCompany:     string;
    clientName:      string;
    nif:             string;
    address:         string;
    cp:              string;
    city:            string;
    country:         string;
    phone:           string;
}

export interface EditClientData {
    uid:             string;
    codeCompany:     string;
    clientName:      string;
    nif:             string;
    address:         string;
    cp:              string;
    city:            string;
    country:         string;
    phone:           string;
    actived:         boolean; 
}

export interface AddClientResponse {
    msg: string;
}

export interface ClientListResponse {
    clients?: Array<Client>;
}

export interface DeleteClientResponse {
    msg: string;
}

export interface CreateUserData {
    email:    string;
    password: string;
    fullname: string;
    actived:  boolean;
}

export interface CreateUserResponse {
    msg: string;
}

export interface UserListResponse {
    users?: Array<User>
}

export interface DeleteUserResponse {
    msg: string;
}

export interface EditUserData {
    uid:      string;
    email:    string;
    fullname: string;
    actived:  boolean;
}

export interface EditUserResponse {
    msg: string;
}

export interface EditClientResponse {
    msg:    string;
    client: Client;
}

export interface CreateClientUserData {
    codigo:   string;
    email:    string;
    password: string;
    fullname: string;
    actived:  boolean;
}

// Passenger /////////////////////////////////////////////////////////////////////////////////////




// Clients ZD

export interface ClientZD {
    codigo: string;
    Nombre: string;
    Nif: string;
    Direccion: string;
    Poblacion: string;
    País: string;
    CP: string;
    Teléfono: string;
}


export interface ClientListZDData {
    empresa: number;
    v2: number;
    codigo: string;
    nombre: string; 
}

export interface ClientListZDResponse {
    msg:    string;
    clients?: Array<ClientZD>;
}

export interface CheckClientResponse {
    msg: string;
    found: boolean;
}
// Expeditions ////////////////////////////////////////////////////

export interface Expedition {
    uid:                string;
    name:               string;
    reference:        string;
    price:              string;
    flatRate:           string;
    quantityFlatRate:   number,
    discount:           string,
    discountPercent:    number,
    maxTimeReservation: number,
}

export interface EditExpeditionData {
    uid:                string;
    referenceZD:        string;
    name:               string;
    origin:             string;
    destination:        string;
    coordsOrigin:       string;
    coordsDestination:  string;
    actived:            boolean;
}

export interface CheckExpeditionResponse {
    msg: string;
    found: boolean;
}

export interface ExpeditionListResponse {
    expeditions?: Array<Expedition>;
}

export interface ExpeditionZDB {
    id: number;
    idroute: number;
    reference: string;
    name: string;
    pax: number;
    workDays: string[];
    wayPoints:  Array<WaypointZD>;
}


export interface ExpeditionListZDData {
    empresa: number;
    v2: number;
    codigo: string;
}


// Notices /////////////////////////////////////////////////////////////////////////

export interface Notice {
    uid:                string;
    title:              string;
    description:        string;
    dateHour:           Date;
    owner:              string;
    codeRoute:          string;
    passengers:         Array<string>,
}

export interface CreateNoticeData {
    title:              string;
    description:        string;
    date:               Date;
    codeClient:         string;
    codeRoute:          string;
}

export interface CreateNoticeResponse {
    msg: string;
    notices: Array<Notice>;
}

export interface NoticeListResponse {
    notices: Array<Notice>;
}

export interface DeleteNoticeResponse {
    msg: string;
}

// Routes ////////////////////////////////////////////////////////////////////////







export interface CheckRouteResponse {
    msg: string;
    found: boolean;
}

export interface AddRouteData { 
    clientId: string; 
    route: RouteZD
}

export interface AddRouteResponse {
    msg: string;
}

export interface EditRouteData {
    uid:                string;
    name:               string;
    actived:            boolean;
}



export interface RouteAddToPassengerData {
    routeId: number;
    passengerId: number;
}

export interface RouteAddToPassengerResponse {
    msg: string;
}

export interface DeleteRouteResponse {
    msg: string;
}

// Waypoint

export interface WaypointZD {
    id: number;
    dropOffPax: number;
    latitude: string;
    longitude: string;
    order: number;
    pickUpPax:  number;
    time: string;
    wayPointName: string;
}




export interface WaypointListResponse {
    waypoints?: Array<Waypoint>;
}

export interface WaypointGrid {
    // waypoint:   string;
    // countUp:    number;
    // countDown:  number;
    id: number;
    address: string;
    countUp: number;
    countDown: number;
    arrivalTime: string; // Correspondiente a reachTimeAt
    departureTime: string; // Correspondiente a leaveTimeAt
    pickup: IPickUpDropOff[];
    dropoff: IPickUpDropOff[];
}

// Bookings 


export interface BookingListData {
    empresa: number; 
    v2: number;
    clientValue:    string;
    routeValue:     string;
    months: number;
}

export interface BookingListData2 {
    empresa: number; 
    v2: number;
    months:         number;
    clientValue:    string;
    routeValue:     string;
}

// export interface BookingListData3 {
//     empresa: number; 
//     v2: number;
//     daySelected:    Date;
//     clientValue:    string;
//     routeValue:     string;
//     expeditionValue: string;

// }

export interface BarChartData {
    labels:                 string[];
    datasets: {
        label:              string;
        data:               number[];
        backgroundColor:    string;
        borderColor:        string;
        borderWidth:        number;
    }[];
}

export interface GroupedBookingsByWaypoint {
    bookingId: Booking[];
}

export interface WaypointCount {
    waypointId: number;
    waypointName: string;
    count: number;
}


// Invoice ///////////////////////////

// export interface InvoiceZDB {
//     numInvoice: string;
//     concept: string;
//     dateInvoice: string;
// }

// export interface InvoiceListResponse {
//     invoices: InvoiceZDB[];
// }



///////////////////////////////////////////////////////////////////////////



// Nuevo RouteZD

export interface RouteZD {
    id: number;
    routeName: string;
    clientName: string;
    clientId: number;
}


export interface Route {
    uid:                string;
    idRouteZD:          number;
    price:              string;
    flatRate:           string;
    quantityFlatRate:   number,
    discount:           string,
    discountPercent:    number,
    maxTimeReservation: number,
}

export interface EditPriceRouteData {
    uid:              string;
    price:            string;
    flatRate:         string;
    quantityFlatRate: number;
    discount:         string;
    discountPercent:  number;
}

export interface EditRouteResponse {
    msg:    string;
    route:  Route;
}

export interface EditOperativeParametersRouteData {
    uid:                string;
    maxTimeReservation: number;
}

export interface GetRouteDaysData {
    routeID: number;
    initialDate?: string; 
    endDate?: string;
}

export interface GetRouteDaysResponse {
    workDays: string[];
    errores: string;
}

export interface GetRouteServicesData {
    routeID: number;
    pickupDates: string[];
}

export interface GetRouteServicesResponse {
    expeditions: ExpeditionZD[];
    errores: string;
}

export interface ExpeditionZD {
    expID:      number;
    expName:    string;
    pax:        number;
    groups:     GroupExpeditionZD[];
}

export interface GroupExpeditionZD {
    groupID:    number;
    wayPoints:  WaypointZD[]; 
}

export interface WaypointZD {
    idByDay:        WaypointIDByDayZD[];
    time:           string;
    order:          number;
    wayPointName:   string;
    latitude:       string;
    longitude:      string;
}

export interface WaypointIDByDayZD {
    idparada:   number;
    idservicio: number;
    fecha:      string;
    pickUpPax:      number;
    dropOffPax:     number;
    operationalstatus: number;
}

export interface ServiceZDB {
    serviceId: number;
    expeditionName: string;
    serviceDate: string;
    stops: ServiceStopZDB[];
}

export interface ServiceStopZDB {
    waypointName: string;
    waypointId: number;
    waypointLatitude: string;
    waypointLongitude: string;
    waypointOrder: number;
    waypointTime: string;
}

export interface Booking {
    uid:                    string;
    clientId:               string;
    passenger:              Passenger;
    routeId:                number;
    routeName:                string;
    expedition:             ExpeditionBooking;
    serviceId:              number;
    groupId:                number;
    waypointOrigin:         WaypointBooking;
    waypointDestination:    WaypointBooking;
    bookingDate:            number;
    canceled:               boolean;
    statusPickup:           number;
    price:                  string;
    flatRate:               string;
    quantityFlatRate:       number;
    discount:               string;
    discountPercent:        number;
}

export interface WaypointBooking {
    waypointId:     number;
    time:           string;
    order:          number;
    pickUpPax:      number;
    dropOffPax:     number;
    wayPointName:   string;
    latitude:       string;
    longitude:      string;
}

export interface ExpeditionBooking {
    expeditionId:   number;
    expeditionName: string;
}

export interface BookingsByRoute {
        empresa:             number;
    v2:                  number;
    clientId: number;
    routeId: number;
}

export interface BookingsDoneByService {
    token: string;
    serviceId: number;
}

export interface BookingsByService {
    empresa: number; 
    v2: number;
    serviceId: number;
}


export interface GetGPSResponse {
    errores: string;
    dataGPS: DataGPS;
}

export interface GetGPSRequest {
    token: string;
    serviceId:           number;
    mediaSpeedMin:       number; 
    skipTimeRestriction: number;
}

export interface DataGPS {
    lat: string;
    lon: string;
    speed: number;
    queryDate: string;
    errores: string;
}

export interface Coordinate {
    lat: number;
    lon: number;
}

export interface ArrivalWaypoint {
    waypointId: number;
    waypointOrder: number;
    waypointName: string;
    waypointTime: string;
    operationalStatus:  number;
    distanceMeters: number;
    etaTime: string;
    lat: number;
    lon: number;
}

export interface GetServiceInfoRequest {
    serviceId:           number;
}

export interface GetServiceInfoResponse {
    errores: string;
    serviceInfo: ServiceInfo;
}

export interface ServiceInfo {
    serviceDate: string;
    waypoint: WaypointServiceZD[];
}

export interface WaypointServiceZD {
    waypointName:       string;
    waypointId:         number;
    waypointLatitude:   string;
    waypointLongitude:  string;
    waypointOrder:      number;
    waypointTime:       string;
    operationalStatus:  number;
    pickUpPax:          number;
    dropOffPax:         number;
}

export interface DateRange {
    startDate: string,
    endDate: string
}

export interface SummaryBookingsRequest {
    empresa: number; 
    v2: number;
    clientCode?: string; 
    startDateSelected: Date;
    endDateSelected: Date;
}

export interface InfoInvoiceGrid { 
    dateTitle: string; 
    dateStart: Date; 
    dateEnd: Date; 
}

export interface SummaryRouteInvoice {
    routeId: number;
    routeName: string;
    totalRoutePrice: number;
    summaryPassengerInvoiceList: SummaryPassengerInvoice[];
}

export interface SummaryPassengerInvoice {
    routeId?: number,
    routeName?: string,
    fullname: string;
    nif: string;
    email: string;
    quantity: number;
    price: string;
    flatRate:  string;
    quantityFlatRate: number;
    discount: string;
    discountPercent: number;
    totalPrice: number;
}

// export interface InvoiceData {
//     id: string;
//     name: string;
// }




export interface BookingMonthsListData {
    empresa: number; 
    v2: number;
    months: number;
}

export interface ExcelGeneralBooking {
    mes: string;
    "reservas validadas": number;
    "reservas no validadas": number;
    "reservas canceladas": number;

}

export interface ExcelRegisteredPassengers {
    mes: string;
    "numero registros": number;
}

export interface ExcelStopsPassengers {
    parada: string;
    "Numero de reservas": number;
}




////////////////////////////////////////////////////////
////////////////////////////////////////////////////////



export enum ResponseStatus {
    Ok = 'ok',
    Error = 'error',
    No_Action = 'no_action'
}

export interface ErrorInfo {
    errCode: string;
    errMessage: string;
}

export interface User {

    rol_web: RoleStatus;
    name: string;
    
    //state:             boolean;
    // rol_web:           string;
}

export interface RouteListZDData {
    // empresa: number;
    // v2: number;
    // codigo?: string;
    token: string;
}

export interface RouteListZDResponse {
    errores: string;
    routes?: Array<RouteZD>
}

export interface LoginResponse {

    token: string;
    name: string;
    errores: string;

}

export interface TokenResponse {
    status: ResponseStatus;
    name: string;
    surname: string;
    id: number;
    errorInfo: ErrorInfo;
}

export enum RoleStatus {
    Admin = 'Admin',
    Client = 'Client',
    User = 'User'
  }

export interface PassengerListData {  
    token: string;
    clientId?: number;
}

export interface PassengerListResponse {
    status:    ResponseStatus;
    errorInfo: ErrorInfo;
    passengers?:   Passenger[];
}
export interface Passenger {
    id: number;
    dni: string;
    name: string;
    phone: string;
    email: string;
    disabled: boolean;
}

export interface EditPassengerData {
    token: string;
    id: number;
    name: string;
    email: string;
    phone: string;
    dni: string;
    disabled: boolean;

}

export interface EditPassengerResponse {
    status:    ResponseStatus;
    errorInfo: ErrorInfo;
}

export interface DeletePassengerData {
    id: number; 
    token: string;
}

export interface DeletePassengerResponse {
    status:    ResponseStatus;
    errorInfo: ErrorInfo;
}

export interface IPassengersInServiceRequest {
    id: number; 
    token: string;
}
export interface IPassengersInServiceResponse {
    status:    ResponseStatus;
    errorInfo: ErrorInfo;
    passengers: Passenger[];
    wayPoints: Waypoint[];
}

export interface Waypoint {
    idWayPoint: number;
    address: string;
    location: ILocation;
    pickupTime: string;
    stopOrder: number;
    reachTimeAt: string;
    leaveTimeAt: string;
    reachLocation: ILocation;
    leaveLocation: ILocation;
    pickUp: IPickUpDropOff[];
    dropOff: IPickUpDropOff[];
}

export interface IPickUpDropOff {
    idPass: number;
    name: string;
    reference: string;
    remarks: string;
    location: ILocation;
    checkedTime: string;
}

export interface ILocation {
    longitude: string;
    latitude: string;
}

export interface ModalState {
    title: string;
    description: string;
    visible: boolean;
    navigation: boolean;
    activityIndicator: boolean;
  }

  export interface BookingListResponse {
    status:    ResponseStatus;
    errorInfo: ErrorInfo;
    bookings?: Array<Booking>;
}
