//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { createContext } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { CreateUserData, CreateUserResponse, UserListResponse, DeleteUserResponse, EditUserResponse, EditUserData, CreateClientUserData } from '../../interfaces/appInterfaces';

type UserContextProps = {
    userCreate: ( createUserData: CreateUserData ) => Promise<CreateUserResponse>;
    userList: ( pageState: number, search?: string, orderList?: string ) => Promise<UserListResponse>;
    userDelete: ( itemId: string ) => Promise<DeleteUserResponse>;
    userEdit: ( editUserData: EditUserData ) => Promise<EditUserResponse>;
    clientUserList: ( codigo: string, pageState: number, search?: string, orderList?: string ) => Promise<UserListResponse>;
    clientUserCreate: ( createClientUserData: CreateClientUserData ) => Promise<CreateUserResponse>;
}

export const UserContext = createContext({} as UserContextProps);

export const UserProvider = ({ children }: any ) => {

 
    const userCreate = async( { email, password, fullname, actived }: CreateUserData ): Promise<CreateUserResponse> => {
        
        let data: CreateUserResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<CreateUserResponse>('/users/createuser', {
                email,
                password,
                fullname,
                state: actived,
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const userList = async( pageState: number, search: string = "", orderList: string = "" ): Promise<UserListResponse>=> {
   
        let data = null;
        const from = pageState * 5 - 5;

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<UserListResponse>(`/users/user-list?from=${from}&search=${search}&orderlist=${orderList}`);
            data = dataResponse;
    

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const userDelete = async( itemId: string ): Promise<DeleteUserResponse> => {
        
        let data: DeleteUserResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.delete<DeleteUserResponse>(`/users/${itemId}`);

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

         return data;
    }

    const userEdit = async( { uid, email, fullname, actived }: EditUserData ): Promise<EditUserResponse> => {
        
        let data: EditUserResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.put<EditUserResponse>(`/users/${uid}`, {
                email,
                fullname,
                state: actived,
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const clientUserList = async( codigo: string, pageState: number, search: string = "", orderList: string = "" ): Promise<UserListResponse>=> {
   
        let data = null;
        const from = pageState * 5 - 5;

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<UserListResponse>(`/users/client-user-list?codigo=${codigo}&from=${from}&search=${search}&orderlist=${orderList}`);
            data = dataResponse;
    

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const clientUserCreate = async( { codigo, email, password, fullname, actived }: CreateClientUserData ): Promise<CreateUserResponse> => {
        
        let data: CreateUserResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<CreateUserResponse>('/users/create-client-user', {
                codigo,
                email,
                password,
                fullname,
                state: actived,
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    return(
        <UserContext.Provider value={{
            userCreate,
            userList,
            userDelete,
            userEdit,
            clientUserList,
            clientUserCreate,
        }}>
            { children }
        </UserContext.Provider>
    )
}
