 //***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

interface Props {
    description: string;
    isValid: boolean;
    dirty: boolean;
    handleSubmit: () => void;
}

export const SubmitButton = ( { description, isValid, dirty, handleSubmit }: Props) => {
  return (
    <button
      className={ 
        !isValid || !dirty
        ? "disabled-submit-button" 
        : "submit-button" 
      } 
      type="submit"
      onClick={ () => handleSubmit() }
      disabled={ !isValid || !dirty }
    >
      { description }
    </button>
  )
}
