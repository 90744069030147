//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useTranslation } from 'react-i18next';

import { RouteSectionListZD } from "../../components/section/RouteSectionListZD";

export const RoutesPage = () => {

  const { t } = useTranslation('global');

  return (

      <div className="page-container">

        <section className="page-section1-container">

          <div className="page-section1-title-group">
            <h1 className="page-title"> { t('title.routes') } </h1>           
          </div>

        </section>

        <RouteSectionListZD />

      </div>
  )
}
