//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { Passenger } from "../../interfaces/appInterfaces";


export interface PassengerState {
    errorMessage: string;
    passenger: Passenger | null;
}

type PassengerAction = 
    | { type: 'selected', payload: { passenger: Passenger } }
    | { type: 'not-selected' }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'clearState' }

 export const passengerReducer = ( state: PassengerState, action: PassengerAction ): PassengerState => {

        switch (action.type) {
            case 'selected':
                return {
                    ...state,
                    passenger: action.payload.passenger,
                }
        
            case 'not-selected':
                return {
                    ...state,
                    passenger: null,
                }

            case 'addError':
                return {
                    ...state,
                    errorMessage: action.payload
                }
            case 'removeError':
                return {
                    ...state,
                    errorMessage: '',
                }
            case 'clearState':
                return {
                    errorMessage: '',
                    passenger: null,
                }

            default:
               return state;
        }
}