import { SideBarMenuCard } from '../../types/types';
import { classNames } from '../../util/classes';

interface SideBarMenuCardViewProps {
  card: SideBarMenuCard;
  isOpen: boolean;
}
export const SideBarMenuCardView = ( { card, isOpen }: SideBarMenuCardViewProps ) => {

  return (
    <div className="SideBarMenuCardView">
      <div className={ classNames( 'profileInfo', !isOpen && "collapsed" ) }>
        <div className="name"> { card.displayName } </div>
      </div>
    </div>
  )
}

