//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { createContext, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { RouteListZDData, RouteListZDResponse,  CheckRouteResponse, RouteZD, Route, EditPriceRouteData, EditRouteResponse, EditOperativeParametersRouteData, GetRouteDaysData, GetRouteDaysResponse, GetRouteServicesData, GetRouteServicesResponse, ExpeditionZD, RouteAddToPassengerData, RouteAddToPassengerResponse, ResponseStatus } from '../../interfaces/appInterfaces';
import { routeReducer, RouteState } from './routeReducer';
import { getEmpresa } from '../../helpers/getEmpresa';
import Keys from '../../constants/Keys';

type RouteContextProps = {
    errorMessage: string;
    route: Route | null;
    routeZD: RouteZD | null;
    routesZD: Array<RouteZD> | null;
    removeError: () => void;
    addError: ( errMessage: string ) => void;
    routeListZD: ( routeListZDData: RouteListZDData ) => Promise<RouteListZDResponse>;
    emptyZDRoutes: () => void;
    routeCheck: ( routeId: Number, passengerId: string ) => Promise<CheckRouteResponse>;
    routeQuitToPassenger: ( routeAddToPassengerData: RouteAddToPassengerData ) => Promise<RouteAddToPassengerResponse>;
    routeAddToPassenger: ( expeditionAddToPassengerData: RouteAddToPassengerData ) => Promise<RouteAddToPassengerResponse>;
    routeSelectedZD: ( routeZD: RouteZD ) => void;
    routePriceEdit: (  editRouteData: EditPriceRouteData )=> Promise<EditRouteResponse>;
    routeOperativeParametersEdit: (  editRouteData: EditOperativeParametersRouteData )=> Promise<EditRouteResponse>;
    getRouteDays: ( getRouteDaysData: GetRouteDaysData ) => Promise<GetRouteDaysResponse>;
    getRouteServices: ( getRouteServicesData: GetRouteServicesData ) => Promise<GetRouteServicesResponse>;
    sortByProperty: ( property: string ) => void;
    clearState: () => void;
}

const routeInicialState: RouteState = {
    errorMessage: '',
    route: null,
    routesZD: [] as RouteZD[],
    routeZD: null,
}

export const RouteContext = createContext({} as RouteContextProps);

export const RouteProvider = ({ children }: any ) => {

    const [ state, dispatch ] = useReducer( routeReducer, routeInicialState );

    const emptyZDRoutes = async() => {
        dispatch({
            type: 'empty-list-route-zd',
        });
    }


    const routeSelectedZD = async( routeZD: RouteZD ) =>  {  

        let data = null;

        try {
            // const { data: dataResponse } = await zdReservasBusApi.get<EditRouteResponse>(`/routesapp/get-route-info?idRouteZD=${routeZD.id}`);

            dispatch({
                type: 'selected-zd',
                payload: {
                    routeZD: routeZD,
                }
            });

            // dispatch({
            //     type: 'selected',
            //     payload: {
            //         route: dataResponse.route,
            //     }
            // });
            // data = dataResponse;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        // return data;
    }

    const routeListZD = async( { token }: RouteListZDData): Promise<RouteListZDResponse> => {

        let data = {} as RouteListZDResponse;
        try {

            const { adminApi, DBEmpresa } = await getEmpresa( Keys.CODE_COMPANY );

            const dataAdmin  = await adminApi.post( '/GetRutas', { JSONString: JSON.stringify(
                { 
                     token
                    
                })});

            data = JSON.parse( dataAdmin.data.d );

            if( data.errores !== ""  ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errores || 'Información incorrecta'
                })
            } else {
    
            if ( data.routes ) {    
                dispatch({
                    type: 'list-route-zd',
                    payload: {
                        routesZD: data.routes,
                    }
                });
            }
            }
        
        } catch (error: any) {
            dispatch({ 
                type: 'addError',
                payload: error.response.data.msg || 'Información incorrecta'
            })
        }

        return data;
    }


    const routeQuitToPassenger = async( { routeId, passengerId }: RouteAddToPassengerData ): Promise<RouteAddToPassengerResponse> => {
        
        let data: RouteAddToPassengerResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<RouteAddToPassengerResponse>('/routesapp/quit-route-passenger', {
                routeId, passengerId
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    
    }

    const routeAddToPassenger = async( { routeId, passengerId }: RouteAddToPassengerData ): Promise<RouteAddToPassengerResponse> => {
        
        let data: RouteAddToPassengerResponse = { msg: '' };

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<RouteAddToPassengerResponse>('/routesapp/add-route-passenger', {
                routeId, passengerId
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }


    const routeCheck = async( routeId: Number, passengerId: string ): Promise<CheckRouteResponse> => {
        
        let data = {} as CheckRouteResponse ;

        try {
            const { data: dataResponse } = await zdReservasBusApi.post<CheckRouteResponse>('/routesapp/check-route', {
                routeId, passengerId
            });

            data = dataResponse;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const routePriceEdit = async( {  uid, price, flatRate, quantityFlatRate, discount, discountPercent  }: EditPriceRouteData )=> {
        
        let data: EditRouteResponse = { msg: '', route: {} as Route };
        
        try {
            const { data: dataResponse } = await zdReservasBusApi.put<EditRouteResponse>(`/routesapp/${uid}`, {
                price, flatRate, quantityFlatRate, discount, discountPercent
            });

           dispatch({
                type: 'selected',
                payload: {
                    route: dataResponse.route,
                }
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

     const routeOperativeParametersEdit = async( {  uid, maxTimeReservation  }: EditOperativeParametersRouteData )=> {
        
        let data: EditRouteResponse = { msg: '', route: {} as Route };

        try {
            const { data: dataResponse } = await zdReservasBusApi.put<EditRouteResponse>(`/routesapp/${uid}`, {
                maxTimeReservation
            });

           dispatch({
                type: 'selected',
                payload: {
                    route: dataResponse.route,
                }
            });

            data.msg = dataResponse.msg;

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const getRouteDays = async( { routeID, initialDate, endDate }: GetRouteDaysData ): Promise<GetRouteDaysResponse>  => {  

        let data = { } as GetRouteDaysResponse;

        try {

            const { adminApi, DBEmpresa } = await getEmpresa( Keys.CODE_COMPANY );

            const dataAdmin  = await adminApi.post( '/getRutaDias', { JSONString: JSON.stringify(
                { 
                    DBempresa: DBEmpresa, routeID: routeID, initialDate, endDate
                })});

           
            data = JSON.parse( dataAdmin.data.d );

            if( data.errores !== ""  ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errores || 'Información incorrecta'
                })
            }
          

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const getRouteServices = async( { routeID, pickupDates }: GetRouteServicesData ): Promise<GetRouteServicesResponse>  => {  

        let data = {} as GetRouteServicesResponse;

        try {
            const { adminApi, DBEmpresa } = await getEmpresa( Keys.CODE_COMPANY );

            const dataAdmin  = await adminApi.post( '/getRutaServicios', { JSONString: JSON.stringify(
                { 
                    DBempresa: DBEmpresa, routeID: routeID, pickupDates: pickupDates
                })});

 
            data = JSON.parse( dataAdmin.data.d );

            if( data.errores !== ""  ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errores || 'Información incorrecta'
                })
            }


        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const sortByProperty = async( property: string ) => {
        
        let sortedRoutes= [] as RouteZD[];

        switch (property) {
            case 'idroute':
                sortedRoutes = [...state.routesZD!].sort((a: RouteZD, b: RouteZD) => (Number(a.id) < Number(b.id)) ? -1 : 1);
                break;
        
            default:
                sortedRoutes = [...state.routesZD!].sort((a: RouteZD, b: RouteZD) => (a.routeName < b.routeName) ? -1 : 1);
                break;
        }

        dispatch({
            type: 'list-route-zd',
            payload: {
                routesZD: sortedRoutes,
            }
        });
              
    }

    const removeError = () => {
        dispatch({ 
            type: 'removeError'
        })
    };

    const addError = (errMessage: string) => {
        dispatch({ 
            type: 'addError',
            payload: errMessage
        })
    };

    const clearState = () => {
        dispatch({ 
            type: 'clearState'
        })
    }


    return(
        <RouteContext.Provider value={{
            ...state,
            emptyZDRoutes,
            routeListZD,
            routeCheck,
            routeQuitToPassenger,
            routeAddToPassenger,
            routeSelectedZD,
            routePriceEdit,
            routeOperativeParametersEdit,
            getRouteDays,
            getRouteServices,
            sortByProperty,
            addError,
            removeError,
            clearState
        }}>
            { children }
        </RouteContext.Provider>
    )
}
