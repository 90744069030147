

//  import { useContext, useState, useEffect } from 'react';

//  import { useTranslation } from 'react-i18next';
 
//  import { PassengerContext } from '../../context/passenger/PassengerContext';
//  import { Passenger, PassengerListResponse, RoleStatus } from '../../interfaces/appInterfaces';
//  import { TabButton } from '../../components/buttons/TabButton';
//  import { SearchInput } from '../../components/inputs/SearchInput';
//  import { OrderSelect } from '../../components/selects/OrderSelect';
//  import { CreatePassengerModal } from '../../components/modals/CreatePassengerModal';
//  import { ContentGrid } from '../../components/lists/ContentGrid';
//  import { ActionModal } from '../../components/modals/ActionModal';
//  import { AuthContext } from '../../context/auth/AuthContext';
// import { ClientContext } from '../../context/client/ClientContext';

// const INITIAL_PAGE = 1;
// const MAX_ITEMS = 3;
// const INITIAL_SEARCH = "";
// const INITIAL_ORDER_BY = "fullname";

// export const PassengerSectionList = () => {
    
//   const { passengerList } = useContext(PassengerContext);
//   const { user, token } = useContext(AuthContext);
//   const { clientZD } = useContext(ClientContext);

//   const [ filteredPassengerList, setFilteredPassengerList ] = useState<Passenger[]>([]);


//   const { t } = useTranslation('global');

//   const [ loading, setLoading ] = useState(true);
//   const [ modalState, setModalState ] = useState({
//     title: '',
//     description: '',
//     visible: false,
//   });
//   const [ modalActionState, setModalActionState ] = useState({
//     title: '',
//     description: '',
//     subtitle: '',
//     itemId: 0,
//     visible: false,
//     action: ''
//   });
//   const [ toggleState, setToggleState ] = useState('all');
//   const [ itemsGrid, setItemsGrid ] = useState<Passenger[] | undefined>([]);

//   const [ totalUsers, setTotalUsers ] = useState({
//     total: 0,
//     actives: 0,
//     inactives: 0,
//   });

//   const [ pageState, setPageState ] = useState(INITIAL_PAGE);
//   const [ search, setSearch ]= useState(INITIAL_SEARCH);
//   const [ orderList, setOrderList ]= useState(INITIAL_ORDER_BY);

//   const columnsPassengerList = [ 
//     {
//       value: 'state',
//       text: t('column.state')
//     },
//     {
//       value: 'email',
//       text: t('column.email')
//     },
//     {
//       value: 'fullname',
//       text: t('column.name')
//     },
//     {
//       value: 'nif',
//       text: t('column.nif')
//     },
//     {
//       value: 'phone',
//       text: t('column.phone')
//     },

//   ];

//   useEffect( () => {

//     const axiosData = async () => {
//       setLoading(true); 

//       let resp = {} as PassengerListResponse;

//       user?.rol_web === RoleStatus.Client
//       ? resp = await passengerList( { token: token! } )
//       : resp = await passengerList( { token: token! } );

//       // Cantidad total de pasajeros
//       const totalPassengers: number = resp.passengers!.length;

//       // Cantidad de pasajeros deshabilitados
//       const disabledPassengers: number = resp.passengers!.filter(passenger => passenger.disabled).length;

//       // Cantidad de pasajeros habilitados
//       const enabledPassengers: number = resp.passengers!.filter(passenger => !passenger.disabled).length;

//       setTotalUsers({
//         total: totalPassengers,
//         actives: enabledPassengers,
//         inactives: disabledPassengers,
//       });

//       setItemsGrid( resp.passengers );  
//       setFilteredPassengerList( resp.passengers!.slice( 0, 3 ) )
//       setLoading(false);    
//     }
//     axiosData()
//       .catch(console.error);

//   }, [ pageState, modalState, modalActionState ]);

//   const toggleTab = (tab: string) => {
//     setToggleState(tab);
//   };

//   const handleChange = ( value: string ) => {
//     setSearch( value );
//   }

//   const onHandleSearch = async() => {
//     setPageState(INITIAL_PAGE);

//     setLoading(true); 

//     setLoading(false);
//   }

//   const handleOrderBy = async( value: string ) => {
//     setPageState(INITIAL_PAGE);
//     setLoading(true); 
//     setOrderList( value );
//     itemsGrid && setItemsGrid( itemsGrid!.slice( 0, 3 ) );
//     setLoading(false);
//   }
  
//   const handleNextPage = () => {
//     setPageState( pageState + 1);
//     const from = (pageState + 1) * 3 - 3;
//     itemsGrid && setItemsGrid( itemsGrid!.slice( from, from + 3 ) );
//   }

//   const handlePreviousPage = () => {
//     setPageState( pageState - 1);
//     const from = (pageState - 1) * 3 - 3;
//     itemsGrid && setItemsGrid( itemsGrid!.slice( from, from + 3 ) );
//   }

//   const handleDelete = (itemId:number) => {
//     setModalActionState({
//       title: t('title.confirmation_delete'),
//       subtitle: t('subtitle.you_sure'),
//       itemId: itemId,
//       description: t('description.confirm_passenger_delete'),
//       visible: true,
//       action: 'delete-passenger'
//     });
//   }

//   const handleAccept = (itemId:number) => {
//     setModalActionState({
//       title: t('title.confirmation_accept'),
//       subtitle: t('subtitle.you_sure'),
//       itemId: itemId,
//       description: t('description.confirm_passenger_accept'),
//       visible: true,
//       action: 'accept-passenger'
//     });
//   }

//   const handleDeny = (itemId:number) => {
//     setModalActionState({
//       title: t('title.confirmation_deny'),
//       subtitle: t('subtitle.you_sure'),
//       itemId: itemId,
//       description: t('description.confirm_passenger_deny'),
//       visible: true,
//       action: 'deny-passenger'
//     });
//   }

//   const onCloseModal = () => {
//     setModalState({
//       title: '',
//       description: '',
//       visible: false,
//     })
//   }

//   const onCloseModalAction = () => {
//     setModalActionState({
//       title: '',
//       description: '',
//       subtitle: '',
//       itemId: 0,
//       visible: false,
//       action: '',
//     });
//   }


//   useEffect( () => {
    
//       setPageState(INITIAL_PAGE);

//       const axiosData = async () => {
//         setLoading(true); 

//         setLoading(false); 
        
//       }
//       axiosData()
//         .catch(console.error);

//   }, [ toggleState, orderList ]);

//   return (
//     <>
//        <section className="page-section2-container">
//           <div className="page-section2-search-group">
//             <OrderSelect handleOrderBy={ handleOrderBy } options={ columnsPassengerList }/>
//             <SearchInput onHandleSearch={ onHandleSearch }  handleChange={ handleChange } search={ search } />
//           </div>

//           <div className="bloc-tabs">
//             <TabButton tab={'all'} descriptionText={`${ t('tab.all') }(${ totalUsers.total })`} toggleState={ toggleState } toggleTab={ toggleTab }  />
//             <TabButton tab={'actives'} descriptionText={`${ t('tab.actives') }(${ totalUsers.actives })`} toggleState={ toggleState } toggleTab={ toggleTab } />
//             <TabButton tab={'inactives'} descriptionText={`${ t('tab.inactives') }(${ totalUsers.inactives })`} toggleState={ toggleState } toggleTab={ toggleTab } /> 
//           </div>
//           <div>
//             <div className="content-tabs">
          
//               {
//                 columnsPassengerList.map( ( column, index ) =>  (
//                   <div key={ column.value }className={ `grid-header${index+1}`}>{ column.text }</div>
//                 ))
//               }
//               {
//                 loading 
//                 ? <div className="lds-dual-ring grid-loading"></div>
//                 : ( filteredPassengerList?.length !== 0 ) 
//                     ? filteredPassengerList!.map( ( item, index ) => (
//                       <ContentGrid key={ item.id } item={ item } index={ index } handleDelete={ handleDelete } handleAccept={ handleAccept } handleDeny={ handleDeny } /> 
//                     ))   
//                     : <div className="no-results-grid"><h1>{ t('description.no_results') }</h1></div>          
//               }              
//             </div>
//           </div>
                      
//           <div>
//             {
//               pageState !== 1 &&  
//                 <button 
//                   className="next-page-button"   
//                   onClick={ handlePreviousPage }    
//                 >
//                   &lt;
//                 </button>             
//             }
            
//             <button 
//               className="page-button"       
//             >
//               { pageState }
//             </button>
//             {
//               itemsGrid &&
//               <button 
//               className={
//                   itemsGrid!.length < MAX_ITEMS
//                       ? "next-page-button-disabled"
//                       : "next-page-button"
//                 }
//               onClick={ handleNextPage }   
//               disabled={itemsGrid!.length < MAX_ITEMS}      
//             >
//               &gt;
//             </button>
//             }

  
//           </div>
                   
//         </section>       
//         { modalState.visible && <CreatePassengerModal modalState={ modalState }  onCloseModal={ onCloseModal }/>}
//         { modalActionState.visible && <ActionModal modalActionState={ modalActionState }  onCloseModalAction={ onCloseModalAction }/>}
//     </>           
//   )
// }

import { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PassengerContext } from '../../context/passenger/PassengerContext';
import { Passenger, PassengerListResponse, RoleStatus } from '../../interfaces/appInterfaces';
import { TabButton } from '../../components/buttons/TabButton';
import { SearchInput } from '../../components/inputs/SearchInput';
import { OrderSelect } from '../../components/selects/OrderSelect';
import { CreatePassengerModal } from '../../components/modals/CreatePassengerModal';
import { ContentGrid } from '../../components/lists/ContentGrid';
import { ActionModal } from '../../components/modals/ActionModal';
import { AuthContext } from '../../context/auth/AuthContext';
import { ClientContext } from '../../context/client/ClientContext';
import { debounce } from 'lodash';

const INITIAL_PAGE = 1;
const MAX_ITEMS = 10;
const INITIAL_SEARCH = "";
const INITIAL_ORDER_BY = "fullname";

export const PassengerSectionList = () => {
  const { passengerList } = useContext(PassengerContext);
  const { user, token } = useContext(AuthContext);
  const { clientZD } = useContext(ClientContext);
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({ title: '', description: '', visible: false });
  const [modalActionState, setModalActionState] = useState({
    title: '',
    description: '',
    subtitle: '',
    itemId: 0,
    visible: false,
    action: ''
  });
  const [toggleState, setToggleState] = useState('all');
  const [reloadList, setReloadList] = useState<boolean>(false);
  const [allPassengers, setAllPassengers] = useState<Passenger[]>([]);
  const [filteredPassengers, setFilteredPassengers] = useState<Passenger[]>([]);
  const [totalUsers, setTotalUsers] = useState({ total: 0, actives: 0, inactives: 0 });
  const [pageState, setPageState] = useState(INITIAL_PAGE);
  const [search, setSearch] = useState(INITIAL_SEARCH);
  const [orderList, setOrderList] = useState(INITIAL_ORDER_BY);

  const columnsPassengerList = [ 
    { value: 'state', text: t('column.state') },
    { value: 'email', text: t('column.email') },
    { value: 'fullname', text: t('column.name') },
    { value: 'nif', text: t('column.nif') },
    { value: 'phone', text: t('column.phone') },
  ];

  // Función para obtener todos los pasajeros
  useEffect(() => {
    const fetchPassengers = async () => {
      setLoading(true);
      try {
        let resp = {} as PassengerListResponse;
        resp = await passengerList({ token: token! });

        // Filtrar pasajeros que tengan al menos las propiedades necesarias
        const validPassengers = resp.passengers?.filter(p => 
          p.name && p.email && p.dni && p.phone
        ) || [];

        setAllPassengers(validPassengers);

        // Calcular los totales
        const totalPassengers = validPassengers.length;
        const disabledPassengers = validPassengers.filter(p => p.disabled).length;
        const enabledPassengers = validPassengers.filter(p => !p.disabled).length;

        setTotalUsers({
          total: totalPassengers,
          actives: enabledPassengers,
          inactives: disabledPassengers,
        });

      } catch (error) {
        setModalState({
          title: t('title.error'),
          description: t('description.error_fetching_passengers'),
          visible: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPassengers();
  }, [ passengerList, token, t, reloadList ]);

  // Función para filtrar y ordenar los pasajeros
  useEffect(() => {
    let passengers = [...allPassengers];

    // Filtrar por estado (all, actives, inactives)
    if (toggleState === 'actives') {
      passengers = passengers.filter(p => !p.disabled);
    } else if (toggleState === 'inactives') {
      passengers = passengers.filter(p => p.disabled);
    }

    // Filtrar por búsqueda
    if (search.trim() !== '') {
      const lowerSearch = search.toLowerCase();
      passengers = passengers.filter(p => 
        (p.name?.toLowerCase() ?? '').includes(lowerSearch) ||
        (p.email?.toLowerCase() ?? '').includes(lowerSearch) ||
        (p.dni?.toLowerCase() ?? '').includes(lowerSearch) ||
        (p.phone?.toLowerCase() ?? '').includes(lowerSearch)
      );
    }

    // Ordenar
    passengers.sort((a, b) => {
      const field = orderList as keyof Passenger;
      const aField = (a[field] ?? '').toString().toLowerCase();
      const bField = (b[field] ?? '').toString().toLowerCase();
      if (aField < bField) return -1;
      if (aField > bField) return 1;
      return 0;
    });

    setFilteredPassengers(passengers);
    setPageState(INITIAL_PAGE); // Reiniciar la página al aplicar filtros
  }, [allPassengers, toggleState, search, orderList]);

  // Manejar la búsqueda con debounce
  const debouncedSearch = useMemo(() => debounce((value: string) => {
    setSearch(value);
  }, 500), []);

  const handleChange = (value: string) => {
    //debouncedSearch(value);
    setSearch(value);
  };

  const onHandleSearch = () => {
    // Para activar la búsqueda inmediatamente, despejar el debounce
    debouncedSearch.flush();
    setPageState(INITIAL_PAGE);
  };

  const handleOrderBy = (value: string) => {
    setOrderList(value);
    setPageState(INITIAL_PAGE);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredPassengers.length / MAX_ITEMS);
    if (pageState < totalPages) {
      setPageState(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageState > 1) {
      setPageState(prevPage => prevPage - 1);
    }
  };

  const handleDelete = (itemId: number) => {
    setModalActionState({
      title: t('title.confirmation_delete'),
      subtitle: t('subtitle.you_sure'),
      itemId,
      description: t('description.confirm_passenger_delete'),
      visible: true,
      action: 'delete-passenger'
    });
 
  };

  // const handleAccept = (itemId: number) => {
  //   setModalActionState({
  //     title: t('title.confirmation_accept'),
  //     subtitle: t('subtitle.you_sure'),
  //     itemId,
  //     description: t('description.confirm_passenger_accept'),
  //     visible: true,
  //     action: 'accept-passenger'
  //   });
  // };

  // const handleDeny = (itemId: number) => {
  //   setModalActionState({
  //     title: t('title.confirmation_deny'),
  //     subtitle: t('subtitle.you_sure'),
  //     itemId,
  //     description: t('description.confirm_passenger_deny'),
  //     visible: true,
  //     action: 'deny-passenger'
  //   });
  // };

  const onCloseModal = () => {
    setModalState({ title: '', description: '', visible: false });
  };

  const onCloseModalAction = () => {
    setModalActionState({
      title: '',
      description: '',
      subtitle: '',
      itemId: 0,
      visible: false,
      action: '',
    });
    setReloadList(!reloadList);
  };

  // Obtener los pasajeros para la página actual
  const paginatedPassengers = useMemo(() => {
    const startIndex = (pageState - 1) * MAX_ITEMS;
    return filteredPassengers.slice(startIndex, startIndex + MAX_ITEMS);
  }, [filteredPassengers, pageState]);

  // Calcular el total de páginas
  const totalPages = Math.ceil(filteredPassengers.length / MAX_ITEMS);

  return (
    <>
      <section className="page-section2-container">
        {/* Grupo de búsqueda y ordenamiento */}
        <div className="page-section2-search-group">
          <OrderSelect handleOrderBy={handleOrderBy} options={columnsPassengerList} />
          <SearchInput onHandleSearch={onHandleSearch} handleChange={handleChange} search={search} />
        </div>

        {/* Pestañas de filtrado */}
        <div className="bloc-tabs">
          <TabButton
            tab={'all'}
            descriptionText={`${t('tab.all')}(${totalUsers.total})`}
            toggleState={toggleState}
            toggleTab={setToggleState}
          />
          <TabButton
            tab={'actives'}
            descriptionText={`${t('tab.actives')}(${totalUsers.actives})`}
            toggleState={toggleState}
            toggleTab={setToggleState}
          />
          <TabButton
            tab={'inactives'}
            descriptionText={`${t('tab.inactives')}(${totalUsers.inactives})`}
            toggleState={toggleState}
            toggleTab={setToggleState}
          />
        </div>

        {/* Tabla de pasajeros */}
        <div>
          <div className="content-tabs">
            {columnsPassengerList.map((column, index) => (
              <div key={column.value} className={`grid-header${index + 1}`}>
                {column.text}
              </div>
            ))}
            {loading ? (
              <div className="lds-dual-ring grid-loading"></div>
            ) : paginatedPassengers.length > 0 ? (
              paginatedPassengers.map( (item,index) => (
                <ContentGrid
                  key={item.id}
                  item={item}
                  index={ index }
                  handleDelete={handleDelete}
                />
              ))
            ) : (
              <div className="no-results-grid">
                <h1>{t('description.no_results')}</h1>
              </div>
            )}
          </div>
        </div>
        {/* Controles de paginación */}
        <div>
          {pageState > 1 && (
            <button className="next-page-button" onClick={handlePreviousPage}>
              &lt;
            </button>
          )}

          <button className="page-button">{pageState}</button>

          {pageState < totalPages && (
            <button className="next-page-button" onClick={handleNextPage}>
              &gt;
            </button>
          )}
        </div>
      </section>

      {/* Modales */}
      {modalState.visible && <CreatePassengerModal modalState={modalState} onCloseModal={onCloseModal} />}
      {modalActionState.visible && (
        <ActionModal modalActionState={modalActionState} onCloseModalAction={onCloseModalAction} />
      )}
    </>
  );
};