//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import L from 'leaflet';

export const StopIcon = L.icon({
  iconUrl: require('../../assets/pngegg.png'),
  iconSize: [55,55], 
});
