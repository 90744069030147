//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ServiceStopZDB } from '../../interfaces/appInterfaces';
import { ServiceZDBContext } from '../../context/serviceZDB/ServiceZDBContext';
import { ActionModal } from '../modals/ActionModal';
import { ContentWaypointGrid } from '../lists/ContentWaypointGrid';


const INITIAL_PAGE = 1;
const MAX_ITEMS = 5;

export const WaypointSectionList = () => {
    
  const { t } = useTranslation('global');  
  const { serviceZDB } = useContext(ServiceZDBContext);
  const [ loading, setLoading ] = useState(true);

  const [ modalActionState, setModalActionState ] = useState({
    title: '',
    description: '',
    subtitle: '',
    itemId: '',
    visible: false,
    action: ''
  });
  const [ itemsGrid, setItemsGrid ] = useState<ServiceStopZDB[] | undefined>([]);
  const [ pageState, setPageState ] = useState(INITIAL_PAGE);
  
  const columnsExpeditionList = [ 
    {
      value: 'order',
      text:  t('column.order')
    },
    {
      value: 'wayPointName',
      text: t('column.name')
    },
    {
      value: 'latitude',
      text:  t('column.location')
    },
    {
      value: 'time',
      text: t('column.time')
    }
  ];

  const handleNextPage = async() => {
    setPageState( pageState + 1);
    const from = (pageState + 1) * 5 - 5;
    serviceZDB && setItemsGrid( serviceZDB.stops.slice( from, from + 5 ) );
  }

  const handlePreviousPage = () => {
      setPageState( pageState - 1);
      const from = (pageState - 1) * 5 - 5;
      serviceZDB && setItemsGrid( serviceZDB.stops.slice( from, from + 5 ) );
  }

  useEffect(() => {
    serviceZDB && setItemsGrid( (serviceZDB.stops).slice( 0, 5 ) );
    setLoading(false);
  }, [ serviceZDB ]);

  const onCloseModalAction = () => {
    setModalActionState({
      title: '',
      description: '',
      subtitle: '',
      itemId: '',
      visible: false,
      action: '',
    });
  }

  return (
      <section className="page-section2-container">

      <div>
        <div className="content-tabs-4-columns">
          {
            columnsExpeditionList.map( ( column, index ) =>  (
              <div key={ column.value }className={ `grid-header${index+1}`}>{ column.text }</div>
            ))
          }
          { loading 
              ? <div className="lds-dual-ring grid-loading"></div>
              : ( itemsGrid?.length !== 0 ) 
                  ? itemsGrid!.map( ( item, index ) => (
                    <ContentWaypointGrid key={ item.waypointId } item={ item } index={ index } /> 
                  ))
                  : <div className="no-results-grid"><h1>{ t('description.no_results') }</h1></div>
          }
        </div>
      </div>
                    
      <div>
        {
          pageState !== 1 &&  
            <button 
              className="next-page-button"   
              onClick={ handlePreviousPage }    
            >
              &lt;
            </button>             
        }
        
        <button 
          className="page-button"       
        >
          { pageState }
        </button>
        <button 
          className={
              itemsGrid!.length < MAX_ITEMS
                  ? "next-page-button-disabled"
                  : "next-page-button"
            }
          onClick={ handleNextPage }   
          disabled={ itemsGrid!.length < MAX_ITEMS }      
        >
          &gt;
        </button>

      </div>
      { modalActionState.visible && <ActionModal modalActionState={ modalActionState }  onCloseModalAction={ onCloseModalAction }/>}
    </section>              
  )
}
