import ReactDOM from 'react-dom/client';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import global_ca from './translations/ca/global.json';

import './styles/styles.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
    ca: {
      global: global_ca
    }
  }
});

root.render(

    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>

);

