//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 01/12/2022
 // 
 //****************************************
 
import { useContext, useEffect, useState } from 'react';

import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { FaRegCheckCircle, FaRegCircle, FaRegStopCircle } from 'react-icons/fa';
import 'leaflet/dist/leaflet.css';

import { ServiceZDBContext } from '../context/serviceZDB/ServiceZDBContext';
import { LoadingPage } from '../pages/loading/LoadingPage';
import { BusIcon } from './maps/BusIcon';
import { StopIcon } from './maps/StopIcon';
import { ArrivalWaypoint, Coordinate } from '../interfaces/appInterfaces';
import { getDistanceBetweenTwoPoints, getETA, getTotalDistance } from '../util/utils';
import { LatLngExpression } from 'leaflet';
import { AuthContext } from '../context/auth/AuthContext';


export const MapView = () => {

  const { token } = useContext( AuthContext );
  const [ arrivalWaypoints, setArrivalWaypoints ] = useState<ArrivalWaypoint[] >([])
  const { serviceZDB, driverInfo , getGPS, getServiceInfo, serviceInfo } = useContext( ServiceZDBContext );

  const  UpdateMapCentre = (props: { mapCentre: LatLngExpression; }) => {
    const map = useMap();
    map.panTo(props.mapCentre);
    return null;
  }

  const stopState = ( stopState: number ) => {
    switch ( stopState ) {
      case -1: return <FaRegCircle className="container-circle" size="1rem" color="#EF8300"/>
      case 0: return <FaRegStopCircle className="container-circle" size="1rem" color="#EF8300"/>
      case 1: return <FaRegCheckCircle className="container-circle" size="1rem" color="#EF8300"/>
    }
  }
 
  useEffect(() => {

      let interval: NodeJS.Timeout | undefined;

        interval = setInterval( async() => { 
          const axiosData = async () => {
            await getGPS( { token: token!, serviceId: serviceZDB!.serviceId, mediaSpeedMin: 5, skipTimeRestriction: 1 } );
            await getServiceInfo( { serviceId: serviceZDB!.serviceId } );
          }
          axiosData()
            .catch(console.error);
          }, 20000);

      return () => {
        clearInterval(interval);
      }
    
  }, []);

  useEffect(() => {

    if( driverInfo ){
      setArrivalWaypoints([]);
      const coordDriver: Coordinate = { lat:  Number(driverInfo.lat), lon:  Number(driverInfo.lon) };

      const calculateArrivalWaypoint = [] as  ArrivalWaypoint[];

      // serviceZDB?.stops.map( waypoint => {

      //   const coordWaypoint: Coordinate = {  
      //     lat: Number( ( waypoint.waypointLatitude ).replace(',','.') ), 
      //     lon: Number( ( waypoint.waypointLongitude ).replace(',','.') ) 
      //   };

      //   if( calculateArrivalWaypoint.length === 0 ){

      //     const distanceKm = getDistanceBetweenTwoPoints( coordDriver, coordWaypoint ); 
      //     const etaTime = getETA( driverInfo.speed , distanceKm * 1000 ); 

      //     calculateArrivalWaypoint.push({
      //       waypointId: waypoint.waypointId,
      //       waypointOrder: waypoint.waypointOrder,
      //       distanceMeters: distanceKm * 1000,
      //       waypointName: waypoint.waypointName,
      //       waypointTime: waypoint.waypointTime,
      //       etaTime: etaTime,
      //       lat:  Number( ( waypoint.waypointLatitude ).replace(',','.') ),
      //       lon:  Number( ( waypoint.waypointLongitude ).replace(',','.') ),
      //     })

      //   } else {

      //     let coordinates = [] as Coordinate[];
      //     coordinates.push(coordDriver);
      //     calculateArrivalWaypoint.map( caw => {

      //       const coordinate: Coordinate = {  
      //         lat: Number( ( caw.lat ) ), 
      //         lon: Number( ( caw.lon ) ) 
      //       };

      //       coordinates.push(coordinate);
      //     });

      //     const distanceKm = getTotalDistance(coordinates);

      //     const etaTime = getETA( driverInfo.speed , distanceKm * 1000 ); 

      //     calculateArrivalWaypoint.push({
      //       waypointId: waypoint.waypointId,
      //       waypointOrder: waypoint.waypointOrder,
      //       waypointName: waypoint.waypointName,
      //       waypointTime: waypoint.waypointTime,
      //       distanceMeters: distanceKm * 1000,
      //       etaTime: etaTime,
      //       lat:  Number( ( waypoint.waypointLatitude ).replace(',','.') ),
      //       lon:  Number( ( waypoint.waypointLongitude ).replace(',','.') ),
      //     })

      //   }

      //   setArrivalWaypoints( calculateArrivalWaypoint ); 
   
      // })

      serviceInfo?.waypoint.map( waypoint => {

        const coordWaypoint: Coordinate = {  
          lat: Number( ( waypoint.waypointLatitude ).replace(',','.') ), 
          lon: Number( ( waypoint.waypointLongitude ).replace(',','.') ) 
        };

        if( calculateArrivalWaypoint.length === 0 ){

          const distanceKm = getDistanceBetweenTwoPoints( coordDriver, coordWaypoint ); 
          const etaTime = getETA( driverInfo.speed , distanceKm * 1000 ); 

          calculateArrivalWaypoint.push({
            waypointId: waypoint.waypointId,
            waypointOrder: waypoint.waypointOrder,
            distanceMeters: distanceKm * 1000,
            waypointName: waypoint.waypointName,
            waypointTime: waypoint.waypointTime,
            operationalStatus: waypoint.operationalStatus,
            etaTime: etaTime,
            lat:  Number( ( waypoint.waypointLatitude ).replace(',','.') ),
            lon:  Number( ( waypoint.waypointLongitude ).replace(',','.') ),
          })

        } else {

          let coordinates = [] as Coordinate[];
          coordinates.push(coordDriver);
          calculateArrivalWaypoint.map( caw => {

            const coordinate: Coordinate = {  
              lat: Number( ( caw.lat ) ), 
              lon: Number( ( caw.lon ) ) 
            };

            coordinates.push(coordinate);
          });

          const distanceKm = getTotalDistance(coordinates);

          const etaTime = getETA( driverInfo.speed , distanceKm * 1000 ); 

          calculateArrivalWaypoint.push({
            waypointId: waypoint.waypointId,
            waypointOrder: waypoint.waypointOrder,
            waypointName: waypoint.waypointName,
            waypointTime: waypoint.waypointTime,
            distanceMeters: distanceKm * 1000,
            operationalStatus: waypoint.operationalStatus,
            etaTime: etaTime,
            lat:  Number( ( waypoint.waypointLatitude ).replace(',','.') ),
            lon:  Number( ( waypoint.waypointLongitude ).replace(',','.') ),
          })

        }

        setArrivalWaypoints( calculateArrivalWaypoint ); 
   
      })

    }

  }, [ driverInfo ]);

  // if ( !driverInfo?.lat ) {
  //     return ( <LoadingPage /> )
  // }

  return (
      <>
          <div className="container-info-waypoints">
            {
              arrivalWaypoints.map( (waypoint, index) => ( 

                    <>
                      {
                        stopState(waypoint.operationalStatus)
                      }
                    
                      <p className="waypoint-text">{ waypoint.waypointName }</p>
                      {
                        index !== arrivalWaypoints.length - 1
                          ? <div className="vl" />
                          : <div className="vl-void" />

                      }
                      <p className="eta-text">Hora estimada llegada: { waypoint.etaTime }</p>
                    </>
              ))
            }
          </div>

          <div className="container-map">
              <MapContainer 
                style={{ height: '100%', width: '100%' }} 
                center={ [ Number(driverInfo?.lat), Number(driverInfo?.lon) ] } 
                zoom={ 14 } 
                scrollWheelZoom={true}
        
              >
              <UpdateMapCentre mapCentre={[ Number(driverInfo?.lat), Number(driverInfo?.lon) ]} />
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              { (driverInfo?.lat && driverInfo?.lon) &&
                  <Marker position={[ Number(driverInfo?.lat), Number(driverInfo?.lon) ]} icon={ BusIcon } >
                      <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                  </Marker>
              }

              { serviceZDB!.stops.map( stop => (
                  <Marker
                      key={stop.waypointId}
                      position={[
                          Number((stop.waypointLatitude).replace(',','.')),
                          Number((stop.waypointLongitude).replace(',','.')),
                      ]}
                      icon={StopIcon}
                  >
                      <Popup>
                      { stop.waypointTime } <br />{ stop.waypointName }
                      </Popup>
                  </Marker>
              ))}
              </MapContainer>
          </div>
      </>
  )
}