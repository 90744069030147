 //***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
interface Props {
    description: string;
    isValid: boolean;
    dirty: boolean;
    ClassName: string;
    handleSubmit: () => void;
}

export const SaveButton = ( { description, isValid, dirty, handleSubmit, ClassName }: Props) => {
  return (
    <button
    className={ 
      !isValid || !dirty
      ? ClassName === "save-button" ? "disabled-save-button" : "disabled-tab-save-button"
      : ClassName 
    } 
    type="submit"
    onClick={ () => handleSubmit() }
    disabled={ !isValid || !dirty }
  >{ description }</button>
  )
}
