//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { createContext, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { Booking, BookingListData, BookingListData2, BookingListResponse, BookingMonthsListData, BookingsByRoute, BookingsByService, BookingsDoneByService, ResponseStatus, SummaryBookingsRequest } from '../../interfaces/appInterfaces';
import { bookingReducer, BookingState } from './bookingReducer';
import { getEmpresa } from '../../helpers/getEmpresa';
import Keys from '../../constants/Keys';

type BookingContextProps = {
    errorMessage: string;
    booking: Booking | null;
    bookingMonthsList: (bookingMonthsListData: BookingMonthsListData) => Promise<BookingListResponse>;
    bookingMonthsListWithFilter: (  bookingListData: BookingListData ) => Promise<BookingListResponse>;
    passengerMostUsedStopsList: (  bookingListData2: BookingListData2 ) => Promise<BookingListResponse>;
    // bookingValidatedDaySelected: (  bookingListData3: BookingListData3 ) => Promise<BookingListResponse>;
    // getBookingsByRoute: ( bookingsByRoute: BookingsByRoute ) => Promise<BookingListResponse>;
    getBookingsDoneByService: ( bookingsDoneByService: BookingsDoneByService ) => Promise<BookingListResponse>;
    getBookingsByService: ( bookingsByService: BookingsByService ) => Promise<BookingListResponse>;
    summaryBookingsDateRange: ( summaryBookingsRequest: SummaryBookingsRequest ) => Promise<BookingListResponse>;
    removeError: () => void;
    addError: ( errMessage: string ) => void;
    clearState: () => void;
}

const bookingInicialState: BookingState = {
    errorMessage: '',
    booking: null,
}

export const BookingContext = createContext({} as BookingContextProps);

export const BookingProvider = ({ children }: any ) => {

    const [ state, dispatch ] = useReducer( bookingReducer, bookingInicialState );

    const bookingMonthsList = async( { empresa, v2, months }: BookingMonthsListData ): Promise<BookingListResponse>=> {
        
        let data = {} as BookingListResponse;
        data.bookings = [];

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-months-list?months=${months}&empresa=${empresa}&v2=${v2}`);
            data = dataResponse;
        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const bookingMonthsListWithFilter = async( { empresa, v2, clientValue, routeValue, months }: BookingListData ): Promise<BookingListResponse>=> {
        
        let data = {} as BookingListResponse;
        data.bookings = [];

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-months-list-filter?client=${clientValue}&route=${routeValue}&months=${months}&empresa=${empresa}&v2=${v2}`);
            data = dataResponse;
        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const passengerMostUsedStopsList = async({ empresa, v2, months, clientValue, routeValue }: BookingListData2 ): Promise<BookingListResponse>=> {
        
        let data = {} as BookingListResponse;
        data.bookings = [];

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-list-filter?months=${months}&client=${clientValue}&route=${routeValue}&empresa=${empresa}&v2=${v2}`);
            data = dataResponse;
        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }



    const getBookingsDoneByService = async({  token, serviceId }: BookingsDoneByService ): Promise<BookingListResponse>=> {
        // console.log('asdasdasd')
        let data = {} as BookingListResponse;
        // data.bookings = [];

        // try {
        //     const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-done-list-by-service?serviceId=${serviceId}&empresa=${empresa}&v2=${v2}`);
        //     data = dataResponse;
        // } catch (error: any) {
        //     data = error.response.data.errors[0] || 'Revise la información'
        // }

        // return data;



        // let data = {} as GetServiceInfoResponse;

        try {

            const { adminApi, DBEmpresa } = await getEmpresa( Keys.CODE_COMPANY );

            const dataAdmin  = await adminApi.post( '/UseService', { JSONString: JSON.stringify(
                { 
                    dbEmpresa: DBEmpresa, serviceId: serviceId
                })});

                data = JSON.parse( dataAdmin.data.d );
    
                if( data.status !== ResponseStatus.Ok ){
                    dispatch({ 
                        type: 'addError',
                        payload: data.errorInfo.errMessage || 'Información incorrecta'
                    })
                } else if ( data.errorInfo.errCode ) {
                    dispatch({ 
                        type: 'addError',
                        payload: data.errorInfo.errMessage || 'Información incorrecta'
                    })
                } 
    
            } catch (error: any) {
                data = error.response.data.errors[0] || 'Revise la información'
            }

        return data;
    }

    const getBookingsByService = async({ empresa, v2, serviceId }: BookingsByService ): Promise<BookingListResponse>=> {
        
        let data = {} as BookingListResponse;
        data.bookings = [];

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-list-by-service?serviceId=${serviceId}&empresa=${empresa}&v2=${v2}`);
            data = dataResponse;
        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }

    const summaryBookingsDateRange = async( { empresa, v2, clientCode, startDateSelected, endDateSelected }: SummaryBookingsRequest ): Promise<BookingListResponse>=> {
   
        let data = null;

        try {
            const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-list-date-range?clientCode=${clientCode}&startDateSelected=${startDateSelected}&endDateSelected=${endDateSelected}&empresa=${empresa}&v2=${v2}`);
            data = dataResponse;
        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        
        return data;
    }

        // const bookingValidatedDaySelected = async({ empresa, v2, daySelected, clientValue, routeValue, expeditionValue  }: BookingListData3 ): Promise<BookingListResponse>=> {
        
    //     let data = {} as BookingListResponse;
    //     data.bookings = [];

    //     try {
    //         const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-list-day-selected?daySelected=${daySelected}&client=${clientValue}&route=${routeValue}&expedition=${expeditionValue}&empresa=${empresa}&v2=${v2}`);
    //         data = dataResponse;
    //     } catch (error: any) {
    //         data = error.response.data.errors[0] || 'Revise la información'
    //     }

    //     return data;
    // }
  
    // const getBookingsByRoute = async({ empresa, v2, routeId, clientId  }: BookingsByRoute ): Promise<BookingListResponse>=> {
        
    //     let data = {} as BookingListResponse;
    //     data.bookings = [];

    //     try {
    //         const { data: dataResponse } = await zdReservasBusApi.get<BookingListResponse>(`/bookings/booking-list-by-route?routeId=${routeId}&clientId=${clientId}&empresa=${empresa}&v2=${v2}`);
    //         data = dataResponse;
    //     } catch (error: any) {
    //         data = error.response.data.errors[0] || 'Revise la información'
    //     }

    //     return data;
    // }

    const removeError = () => {
        dispatch({ 
            type: 'removeError'
        })
    };

    const addError = (errMessage: string) => {
        dispatch({ 
            type: 'addError',
            payload: errMessage
        })
    };

    const clearState = () => {
        dispatch({ 
            type: 'clearState'
        })
    }

    return(
        <BookingContext.Provider value={{
            ...state,
            bookingMonthsList,
            bookingMonthsListWithFilter,
            passengerMostUsedStopsList,
            getBookingsDoneByService,
            getBookingsByService,
            summaryBookingsDateRange,
            // bookingValidatedDaySelected,
            // getBookingsByRoute,
            removeError,
            addError,
            clearState
        }}>
            { children }
        </BookingContext.Provider>
    )
}
