//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
import { useEffect } from 'react';


import { useTranslation } from 'react-i18next';

import { PlacesProvider } from './context';
import { AuthProvider } from './context/auth/AuthContext';
import { UserProvider } from './context/user/UserContext';
import { PassengerProvider } from './context/passenger/PassengerContext';
import { ClientProvider } from './context/client/ClientContext';
import { NoticeProvider } from './context/notice/NoticeContext';
import { RouteProvider } from './context/route/RouteContext';
import { BookingProvider } from './context/booking/BookingContext';
import { ServiceZDBProvider } from './context/serviceZDB/ServiceZDBContext';
import { AppRouter } from './router/AppRouter';

// getpassangerinfo

const AppState = ({ children }: any) => {

  const { i18n } = useTranslation('global');

  useEffect(() => { 
    const asyncData = async () => { 
      const storedLang = await localStorage.getItem('language');
      i18n.changeLanguage(storedLang!);
    }
    asyncData();
  }, [])

  return (
    <AuthProvider>
      <PassengerProvider>
        <ClientProvider>
          <UserProvider>
              <NoticeProvider>
                <RouteProvider>
                  <PlacesProvider>
                    <BookingProvider>
                        <ServiceZDBProvider>
                            { children }
                        </ServiceZDBProvider>
                    </BookingProvider>
                  </PlacesProvider>
                </RouteProvider>
              </NoticeProvider>
          </UserProvider>
        </ClientProvider>
      </PassengerProvider>
    </AuthProvider>
  )
}

function App() {

  return (
        <AppState>
          <AppRouter />
        </AppState>
  );
}

export default App;
