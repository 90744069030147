import moment from "moment";
import 'moment/locale/es'; 
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";

import { Coordinate, ExcelGeneralBooking, ExcelRegisteredPassengers, ExcelStopsPassengers, SummaryPassengerInvoice } from "../interfaces/appInterfaces";

export const getStringDate = ( dateToString: Date  ): string => {

  const stringDate = moment(dateToString).locale('es').format("DD-MM-YYYY HH:mm");
  return stringDate;

}

export const getTimeStamp = ( stringToTimestamp: string  ): number => {
  const [  day, month, year ] = stringToTimestamp.split('/');
  const timestampDate = new Date(+year, +month - 1, +day).getTime();
  return timestampDate;
}

export const getDateTimeStamp = ( stringDate: string, countDownTime: string  ): number => {

  const [  day, month, year ] = stringDate.split('/');

  const timeStampDate = new Date(+year, +month - 1, +day).getTime();
  const hourMinute = countDownTime.split(':');
  const dateAndTimeInTimeStamp = ( timeStampDate +  ( Number(hourMinute[0]) * 3600000 ) + ( Number(hourMinute[1]) * 60000 ) );

  return dateAndTimeInTimeStamp;
}

export const getDateTimeStamp2 = ( stringDate: string ): number => {

  const [  day, month, year ] = stringDate.split('/');

  const timeStampDate = new Date(+year, +month - 1, +day).getTime();


  return timeStampDate;
}

export const getDistanceBetweenTwoPoints = ( cord1: Coordinate, cord2: Coordinate ) => {

  if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
    return 0;
  }

  const radlat1 = (Math.PI * cord1.lat) / 180;
  const radlat2 = (Math.PI * cord2.lat) / 180;

  const theta = cord1.lon - cord2.lon;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344; //convert miles to km
  return dist;
}

export const getTotalDistance = (coordinates: Coordinate[]) => {
  coordinates = coordinates.filter((cord) => {
    if (cord.lat && cord.lon) {
      return true;
    }
  });
  
  let totalDistance = 0;

  if (!coordinates) {
    return 0;
  }

  if (coordinates.length < 2) {
    return 0;
  }

  for (let i = 0; i <= coordinates.length - 2; i++) {
    if (
      !coordinates[i].lon ||
      !coordinates[i].lat ||
      !coordinates[i + 1].lon ||
      !coordinates[i + 1].lat
    ) {
      totalDistance = totalDistance;
    }
    totalDistance =
      totalDistance +
      getDistanceBetweenTwoPoints(coordinates[i], coordinates[i + 1]);
  }

  return totalDistance;
}

export const getETA = ( speedDriver: number, distanceWaypoint: number ): string => {
  const currentDate = new Date();
  const etaSeconds = distanceWaypoint / speedDriver;
  const minutes = Math.floor( etaSeconds / 60 );
  const xMinutesLater = new Date( currentDate.getTime() + ( minutes * 60 * 1000));
  const hoursAndMinutes = xMinutesLater.getHours() + ':' + xMinutesLater.getMinutes();
  return hoursAndMinutes;
}

export const checkOutDate = ( countDownDate: string, countDownTime: string  ): boolean => {

  const now = new Date().getTime();
  const [  day, month, year ] = countDownDate.split('/');
  const timeStampDate = new Date(+year, +month - 1, +day).getTime();
  const hourMinute = countDownTime.split(':');
  const timeleft = (timeStampDate +  ( Number(hourMinute[0]) * 3600000 ) + ( Number(hourMinute[1]) * 60000 ) ) - now;

  return timeleft > 0;
}

export const calculatePricePassenger = ( passenger: SummaryPassengerInvoice ): number => {
        
  let total: number = 0;

  passenger.quantity >= passenger.quantityFlatRate && passenger.quantityFlatRate !== 0
  ? total = Number(passenger.flatRate)
  : total = passenger.quantity * Number(passenger.price)

  return total;
};

export const differenceInMonths = (date1: Date, date2: Date) => {
  const monthDiff = date1.getMonth() - date2.getMonth();
  const yearDiff = date1.getFullYear() - date2.getFullYear();

  return monthDiff + yearDiff * 12;
}

export const createExcel = ( dataToSave: ExcelGeneralBooking[] | ExcelRegisteredPassengers[] | ExcelStopsPassengers[], fileName: string ) => {

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  if( dataToSave ){ 
      const ws = XLSX.utils.json_to_sheet(dataToSave);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
  };
  
}