//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 05/11/2022
 // 
 //****************************************

import { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import es from 'date-fns/locale/es';

import { ExpeditionZD, GroupExpeditionZD, ServiceZDB, WaypointZD } from '../../interfaces/appInterfaces';
import { getDateTimeStamp2, getTimeStamp } from '../../util/utils';
import { OrderSelect } from '../selects/OrderSelect';
import { RouteContext } from '../../context/route/RouteContext';
import { ContentServiceGrid } from '../lists/ContentServiceGrid';
import DatePicker,{ registerLocale }  from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const INITIAL_PAGE = 1;
const MAX_ITEMS = 10;

const esLocale = es as any;
registerLocale( 'es', esLocale );

export const ServiceDoneSectionList = () => {
    
  const { t } = useTranslation('global');  
  const { routeZD, getRouteDays, getRouteServices } = useContext(RouteContext);
  const [ loading, setLoading ] = useState(false);

  const [ serviceList, setServiceList ] = useState<ServiceZDB[]>([]);
  const [ itemsGrid, setItemsGrid ] = useState<ServiceZDB[]>([]);
  const [ pageState, setPageState ] = useState(INITIAL_PAGE);
  const [ dateRange, setDateRange ] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
  const [ startDate, endDate ] = dateRange;
  const [ orderState, setOrderState ] = useState('date');

  const orderByList = [ 
    {
      value: 'date',
      text: t('column.date')
    },  
    {
        value: 'expedition',
        text: t('column.expedition')
    }
  ];

  const columnsExpeditionList = [ 
    {
      value: 'date',
      text: t('column.date')
    },
    {
      value: 'expedition',
      text:  t('column.expedition')
    },
    {
      value: 'origin',
      text: t('column.origin')
    },
    {
      value: 'destination',
      text: t('column.destination')
    },
    {
      value: 'time',
      text:  t('column.time')
    },

  ];

  useEffect(() => {
    setLoading(true);  
    let sortedServices = serviceList;
    sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
    setItemsGrid( sortedServices.slice(0,MAX_ITEMS) );
    setLoading(false);  
  }, [serviceList])

  const handleOrderBy = async( value: string ) => {
    setPageState(INITIAL_PAGE);
    setLoading(true);
    let sortedServices: ServiceZDB[] = [];
    switch (value) {
      case 'expedition':
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( a.expeditionName < b.expeditionName ) ? -1 : 1);
          break;

      default:
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
          break;
    }
    setItemsGrid( sortedServices.slice( 0, MAX_ITEMS ) );
    setLoading(false);
  }

  const handleNextPage = async() => {
    setPageState( pageState + 1);
    const from = (pageState + 1) * MAX_ITEMS - MAX_ITEMS;
    let sortedServices: ServiceZDB[] = [];
    switch (orderState) {
      case 'expedition':
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( a.expeditionName < b.expeditionName ) ? -1 : 1);
          break;

      default:
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
          break;
    }
    setItemsGrid( sortedServices.slice( from, from + MAX_ITEMS ) );
  }

  const handlePreviousPage = () => {
    setPageState( pageState - 1);
    const from = (pageState - 1) * MAX_ITEMS - MAX_ITEMS;
    let sortedServices: ServiceZDB[] = [];
    switch (orderState) {
      case 'expedition':
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( a.expeditionName < b.expeditionName ) ? -1 : 1);
          break;

      default:
          sortedServices = serviceList;
          sortedServices = [...serviceList ].sort(( a: ServiceZDB, b: ServiceZDB ) => ( getDateTimeStamp2(a.serviceDate) < getDateTimeStamp2(b.serviceDate) ) ? -1 : 1);
          break;
    }
    setItemsGrid( sortedServices.slice( from, from + MAX_ITEMS ) );
}

  const onChangeRangeDate = async( [ startDateSelected, endDateSelected ]: [any, any] ) => {
    setDateRange([ startDateSelected, endDateSelected ]);

    if( startDateSelected && endDateSelected ){
      setServiceList([]);
      setLoading(true); 
      const resp = await getRouteDays({ routeID: routeZD!.id, initialDate: startDateSelected.toLocaleDateString('es-ES'), endDate: endDateSelected.toLocaleDateString('es-ES') });
      if( resp.workDays.length > 0 ) {
        const resp2 = await getRouteServices( { routeID: routeZD!.id, pickupDates: resp.workDays  } );
        let countexpeditions = 0; 
        resp2.expeditions.map( ( expedition: ExpeditionZD ) => {
          countexpeditions = countexpeditions + 1;
          let countgroups = 0;
          expedition.groups.map( ( group: GroupExpeditionZD ) =>  {
            countgroups = countgroups + 1;
            group.wayPoints[0].idByDay.map( ( idByDay, index ) => {
              const stops = group.wayPoints.map( ( waypoint: WaypointZD ) => {
                const waypointReturned = {
                  waypointName: waypoint.wayPointName,
                  waypointId: waypoint.idByDay[index].idparada,
                  waypointLatitude: waypoint.latitude,
                  waypointLongitude: waypoint.longitude,
                  waypointOrder: waypoint.order,
                  waypointTime: waypoint.time,
                };
                return waypointReturned;  
              })
  
              setServiceList( prevState => [ ...prevState,  {
                serviceId: idByDay.idservicio,
                expeditionName: expedition.expName,
                serviceDate: idByDay.fecha,
                stops: stops
              }]);
  
            });
          });
  
        });
      }
      setLoading(false); 
    }
  }


  
  return (
    <section className="page-section2-container">

      <div className="page-section2-search-group">
        <OrderSelect handleOrderBy={ handleOrderBy } options={ orderByList } />
        <div className="form-input-component">
            <label className="form-label" > Selecciona Fecha </label>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={ (newValue: any) => onChangeRangeDate(newValue) }
              locale="es"
              dateFormat="P"
              isClearable={true}
              maxDate={ new Date()  }
              className="form-input-field"
            />
        </div>
      </div>

      <div>
        <div className="content-tabs">
          {
            columnsExpeditionList.map( ( column, index ) =>  (
              <div key={ column.value }className={ `grid-header${index+1}`}>{ column.text }</div>
            ))
          }
          {
              loading 
                ? <div className="lds-dual-ring grid-loading"></div>          
                : itemsGrid.length > 0 
                  ?
                    itemsGrid.map( ( item, index ) => (
                      <ContentServiceGrid key={ index } item={ item } index={ index } /> 
                    ))  
                  :  <div className={ "grid-no-content grey-background" }>{t('description.select_date_range')}</div>       
          }
        </div>
      </div>
                    
      <div>
        {
          pageState !== 1 &&  
            <button 
              className="next-page-button"   
              onClick={ handlePreviousPage }    
            >
              &lt;
            </button>             
        }
        
        <button className="page-button" >
          { pageState }
        </button>

        {
          (itemsGrid) &&
          <button 
          className={
              itemsGrid!.length < MAX_ITEMS
                  ? "next-page-button-disabled"
                  : "next-page-button"
            }
          onClick={ handleNextPage }   
          disabled={ itemsGrid!.length < MAX_ITEMS }      
        >
          &gt;
        </button>
        }

      </div>
      
    </section>              
  )
}
