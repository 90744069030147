//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { PlacesState } from "./PlacesProvider";

type PlacesAction = { type: 'setDriverLocation', payload: { isLoading: boolean , driverLocation: [ number, number ]} }


 export const placesReducer = ( state: PlacesState, action: PlacesAction ): PlacesState => {

        switch (action.type) {
            case 'setDriverLocation':
                return {
                    ...state,
                    isLoading: false,
                    driverLocation: action.payload.driverLocation,
                }

            default:
               return state;
        }
}