 //***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

interface Props {
    description: string;
    handleCancel: () => void;
}

export const CancelButton = ( { description, handleCancel }: Props) => {
  return (
    <button
      className={ "cancel-button" } 
      onClick={ () => handleCancel() }
    >
      { description }
    </button>
  )
}
