// ../../components/modals/LoadingModal.jsx

interface LoadingModalProps {
    message: string;
  }

export const LoadingModal: React.FC<LoadingModalProps> = ({ message }) => {
  return (
    <div className="loading-modal-overlay">
      <div className="loading-modal-content">
        <div className="spinner"></div>
        <p>{message}</p>
      </div>
    </div>
  );
};