//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { ZDBusBooking } from './ZDBusBooking';

import { RecoverPasswordPage } from '../pages/account/RecoverPasswordPage';
import { LoadingPage } from '../pages/loading/LoadingPage';
import { VerificationCodePage } from '../pages/account/VerificationCodePage';
import { NewPasswordPage } from '../pages/account/NewPasswordPage';
import { LoginPage } from '../pages/account/LoginPage';


export const AppRouter = () => {

    const { status } = useContext( AuthContext );

    if ( status === 'checking' ) return <LoadingPage />
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                } />
                {/* <Route path="/recover-password-page" element={
                    <PublicRoute>
                        <RecoverPasswordPage />
                    </PublicRoute>
                } />
                <Route path="/verification-code-page" element={
                    <PublicRoute>
                        <VerificationCodePage />
                    </PublicRoute>
                } />
                <Route path="/new-password-page" element={
                    <PublicRoute>
                        <NewPasswordPage />
                    </PublicRoute>
                } /> */}
                <Route path="/*" element={ 
                    <PrivateRoute>
                            <ZDBusBooking />
                    </PrivateRoute> 
                }/>
        
            </Routes>
        </BrowserRouter>
    )
}
