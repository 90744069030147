 //***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

interface Props {
    descriptionText: string;
    tab: string; 
    toggleState: string;
    toggleTab: ( toggleState: string ) => void;
}

export const TabButton = ( { tab, descriptionText, toggleState, toggleTab }: Props ) => {
  return (
    <button
      className={toggleState === tab ? "tabs active-tabs" : "tabs"}
      onClick={() => {
        toggleTab(tab)
      }}
    >
      { descriptionText }
    </button>
  )
}
