//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ServiceZDB } from '../../interfaces/appInterfaces';
import { ServiceZDBContext } from '../../context/serviceZDB/ServiceZDBContext';

interface Props {
    item: ServiceZDB;
    index: number;
}

export const ContentServiceGrid = ( { item, index }: Props ) => {

  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const { serviceZDBSelected } = useContext(ServiceZDBContext)

  const handleDetail = ( serviceZDB: ServiceZDB ) => {
    serviceZDBSelected( serviceZDB );
    navigate("/serviceZDB-detail", { 
      replace: true, 
    });
  };

  return (
    <>
      <div className={ index%2!==0 ? "grid-content1 grey-background " : "grid-content1" }>{ item.serviceDate }</div>
      <div className={ index%2!==0 ? "grid-content2 grey-background " : "grid-content2" }>{ item.expeditionName }</div>
      <div className={ index%2!==0 ? "grid-content3 grey-background " : "grid-content3" }>{ item.stops[0].waypointName }</div>
      <div className={ index%2!==0 ? "grid-content4 grey-background " : "grid-content4" }>{ item.stops[ item.stops.length - 1 ].waypointName }</div>
      <div className={ index%2!==0 ? "grid-content5 grey-background " : "grid-content5" }>{ item.stops[0].waypointTime + ' / ' + item.stops[ item.stops.length-1 ].waypointTime }</div>
      <div className={ index%2!==0 ? "grid-content6 grey-background " : "grid-content6" }></div>   
      <div className={ index%2!==0 ? "grid-content7 grey-background " : "grid-content7" }>
        <button 
          className="details-button"
          onClick={ () =>handleDetail(item) }
        >
          { t('button.details') }
        </button> 
      </div>
    </>
  )
}
