import { SideBarMenuItem, SideBarMenuCard } from '../../types/types';
import { useState } from 'react';
import { classNames } from '../../util/classes';
import { VscMenu } from 'react-icons/vsc';
import { SideBarMenuCardView } from './SideBarMenuCardView';
import { SideBarMenuItemView } from './SideBarMenuItemView';

interface SideBarMenuProps {
  items: SideBarMenuItem[];
  card: SideBarMenuCard;
}

export const SideBarMenu = ( { items, card }:SideBarMenuProps ) => {

  const [ isOpen, setIsOpen ] = useState<boolean>(true);

  const handleClick = () => {
    setIsOpen( !isOpen );
  }

  return (
    
    <nav className={ classNames('SideBarMenu', isOpen ? "expanded" : "collapsed" ) }>
      <div className="menuSection">
        <div className={ classNames('menuButton', isOpen ? "expanded" : "collapsed" ) }>
          <VscMenu className="hamburguerIcon" onClick={ handleClick }/>
        </div>
    
        <SideBarMenuCardView card={ card } isOpen={ isOpen } />
      </div>

      { 
        items.map( item => (
          <SideBarMenuItemView key={ item.id } item={ item } isOpen={ isOpen }/>
        ))
      }
    </nav>
  );
};


    

