//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************

import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ServiceZDBContext } from '../../../context/serviceZDB/ServiceZDBContext';
import { CustomModal } from '../../modals/CustomModal';

export const ServiceZDBDetails = () => {

    const { t } = useTranslation('global');
    const { serviceZDB } = useContext(ServiceZDBContext);
    const [ modalErrorState, setModalErrorState ] = useState({
        title: '',
        description: '',
        visible: false,
        navigation: false,
        activityIndicator: false,
      });
    
    const onCloseModalError = () => {
        setModalErrorState({
          title: '',
          description: '',
          visible: false,
          navigation: false,
          activityIndicator: false,
        });
    }

    return (
        <>
            <div className="detail-info" >
                <div>
                    <h4 className="label" >{ t('label.service') }:</h4>
                    <p className="info">{ serviceZDB?.serviceId }</p>
                </div>
                <div>
                    <h4 className="label" >{ t('label.date') }:</h4>
                    <p className="info">{ serviceZDB?.serviceDate }</p>
                </div>
                <div>
                    <h4 className="label" >{ t('label.expedition') }:</h4>
                    <p className="info">{ serviceZDB?.expeditionName }</p>
                </div>
            </div>
            { modalErrorState.visible && <CustomModal modalState={ modalErrorState } onCloseModal={ onCloseModalError }/>}
        </>
  )
}
