//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { createContext, useReducer } from 'react';

import zdReservasBusApi from '../../api/zdReservasBusApi';

import { DataGPS, GetGPSRequest, GetGPSResponse, GetServiceInfoRequest, GetServiceInfoResponse, ServiceInfo, ServiceZDB } from '../../interfaces/appInterfaces';
import { serviceZDBReducer, ServiceZDBState } from './serviceZDBReducer';
import { getEmpresa } from '../../helpers/getEmpresa';
import Keys from '../../constants/Keys';

type ServiceZDBContextProps = {
    errorMessage: string;
    serviceZDB: ServiceZDB | null;
    driverInfo: DataGPS | null;
    serviceInfo: ServiceInfo | null;
    serviceZDBSelected: ( serviceZDB: ServiceZDB ) => void;
    getGPS: ( getGPSRequest: GetGPSRequest ) => Promise<GetGPSResponse>;
    getServiceInfo: ( getServiceInfoRequest: GetServiceInfoRequest ) => Promise<GetServiceInfoResponse>;
}

const serviceZDBInicialState: ServiceZDBState = {
    errorMessage: '',
    serviceZDB: null,
    driverInfo: null,
    serviceInfo: null,
}

export const ServiceZDBContext = createContext({} as ServiceZDBContextProps);

export const ServiceZDBProvider = ({ children }: any ) => {

    const [ state, dispatch ] = useReducer( serviceZDBReducer, serviceZDBInicialState );

    const serviceZDBSelected = async( serviceZDB: ServiceZDB )=> {
        dispatch({
            type: 'selected',
            payload: {
                serviceZDB: serviceZDB,
            }
        });
    }

    const getGPS = async( { token,serviceId, mediaSpeedMin, skipTimeRestriction }: GetGPSRequest ): Promise<GetGPSResponse> => {
        
        let data = {} as GetGPSResponse;

        try {
            const { adminApi, DBEmpresa } = await getEmpresa( Keys.CODE_COMPANY );

            const dataAdmin  = await adminApi.post( '/GetGPS', { JSONString: JSON.stringify(
            { 
                DBempresa: DBEmpresa, token, codServicio: serviceId, mediaSpeedMin: mediaSpeedMin, skipTimeRestriction: skipTimeRestriction
            })});

            data = JSON.parse( dataAdmin.data.d );

            if( data.errores !== ""  ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errores || 'Información incorrecta'
                })
            } else {
                dispatch({
                    type: 'setDriverLocation',
                    payload: {
                        driverInfo: data.dataGPS,
                    }
                });
            }


        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }


    const getServiceInfo = async( { serviceId }: GetServiceInfoRequest ): Promise<GetServiceInfoResponse> => {
        
        let data = {} as GetServiceInfoResponse;

        try {

            const { adminApi, DBEmpresa } = await getEmpresa( Keys.CODE_COMPANY );

            const dataAdmin  = await adminApi.post( '/getServicioInfo', { JSONString: JSON.stringify(
                { 
                    dbEmpresa: DBEmpresa, serviceId: serviceId
                })});

            data = JSON.parse( dataAdmin.data.d );
            
            if( data.errores !== ""  ){
                dispatch({ 
                    type: 'addError',
                    payload: data.errores || 'Información incorrecta'
                })
            } else {
                dispatch({
                    type: 'setServiceInfo',
                    payload: {
                        serviceInfo: data.serviceInfo,
                    }
                });
            }

        } catch (error: any) {
            data = error.response.data.errors[0] || 'Revise la información'
        }

        return data;
    }
    

    return(
        <ServiceZDBContext.Provider value={{
            ...state,
            serviceZDBSelected,
            getGPS,
            getServiceInfo
        }}>
            { children }
        </ServiceZDBContext.Provider>
    )
}
