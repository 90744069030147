//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface Props {
  value: string,
  onChange: (value: string) => Promise<void>,
  children: JSX.Element | JSX.Element[],
}

export const LanguageSwitch = ({ value, onChange, children }: Props) => {

    return (      
        <>
          <select value={ value } className="select-language" name="orderBy" id="selectby" onChange={ (e) => onChange( e.target.value ) } children={children} />
        </>
    );
};

