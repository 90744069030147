//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/auth/AuthContext';
import { RoleStatus } from '../interfaces/appInterfaces';


export const ClientRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {

    const { user } = useContext(AuthContext);
    const { pathname, search } = useLocation();
    
    localStorage.setItem('lastPath', pathname + search );
    
    return user?.rol_web === RoleStatus.Client 
        ? children
        : <Navigate to="/passengers" />
}
