import { FaMapMarkedAlt } from 'react-icons/fa';

import { ServiceStopZDB } from '../../interfaces/appInterfaces';

interface Props {
    item: ServiceStopZDB;
    index: number;
}

export const ContentWaypointGrid = ( { item, index }: Props ) => {

  const handleClick = () => {

    const latitude = (item.waypointLatitude).replace(',','.');
    const longitude = (item.waypointLongitude).replace(',','.');

    window.open(`http://maps.google.com/maps?q=${latitude},${longitude}`);
  };

  return (
    <>
      <div className={ index%2!==0 ? "grid-columns-4-content1 grey-background " : "grid-columns-4-content1" }>{ item.waypointOrder }</div>
      <div className={ index%2!==0 ? "grid-columns-4-content2 grey-background " : "grid-columns-4-content2" }>{ item.waypointName }</div>
      <div className={ index%2!==0 ? "grid-columns-4-content3-map grey-background " : "grid-columns-4-content3-map" }><div className="mapIcon"><FaMapMarkedAlt size={ 30 } onClick={ handleClick }/></div></div>
      <div className={ index%2!==0 ? "grid-columns-4-content4 grey-background " : "grid-columns-4-content4" }>{ item.waypointTime }</div>
    </>
  )
}
