//***************************************
 //
 // Creado por: Miguel Sánchez
 // Fecha ultima modificación: 30/11/2022
 // 
 //****************************************
 
import { useTranslation } from 'react-i18next';

export const LoadingPage = () => {

  const { t } = useTranslation('global');
  
  return (
    <div style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }}>
      {/* { t('title.charging') } */}
      <div className="lds-dual-ring grid-loading"></div>
      
    </div>
  )
}
